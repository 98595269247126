import * as React from 'react'
import { connect } from 'react-redux';
import { Alert, Badge } from 'reactstrap';
import TranslatableText from "../../Language/TranslateableText";
import { ApplicationState } from '../../store';

interface Props {
    tag: string;
    createdBy?: string;
    createdOn: string; 
    deleted: boolean;
    language: any| undefined
}
export const CaseEntryTag: React.FC<Props> = ({ tag, createdOn, createdBy, deleted, language }) => {
    var isDeleted;
    switch(deleted){
        case true:
            isDeleted = language["removed"]
            break;
        case false:
            isDeleted = language["added"]
            break;
    }
    return (
        <div>
            <Alert color="secondary">
                <small className="font-weight-light">{createdOn} &nbsp; {createdBy} <TranslatableText translationKey="changed_tags" /></small>
                <p>{isDeleted} <Badge>{tag}</Badge> </p> 
          </Alert>
      </div> 
    );
};  

const mapState = (state: ApplicationState) => ({
    language: state && state.language.language_resources
} as Props)

export default connect(mapState)(CaseEntryTag)
