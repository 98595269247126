import React, { ChangeEvent, FormEvent } from 'react';
import { connect } from 'react-redux';
import { CaseEntryCommentClient, ContactClient, CreateCaseEntryCommentRequest, SendCaseUpdateByAdministratorEmailCommand } from '../../ApiClient'
import { ApiConfig } from '../../ApiConfig';
import TranslatableText from "../../Language/TranslateableText";
import { ApplicationState } from '../../store';

interface IProps {
    callback: any,
    caseId: string,
    id: string | undefined,
    language: any | undefined
}
interface IState {
    caseId : string,
    comment: string
}
class CommentForm extends React.Component<IProps, IState> {
    constructor(props:IProps) {
        super(props);
        this.state = {
            caseId : '',
            comment: ''
        } 
    }
   
    handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({ 
            caseId: this.props.caseId,
            comment: e.target.value 
        });
    }

    handleSubmit = (e: any) =>{
        e.preventDefault();

        let commentClient = new CaseEntryCommentClient(new ApiConfig());
        let contactClient = new ContactClient(new ApiConfig());

        commentClient.caseEntryComment(new CreateCaseEntryCommentRequest(this.state))
            .then(res => {
                this.handleOnSuccess();
                this.setState({comment: ''})
                
                if(this.props.id != undefined){
                    contactClient.caseUpdateByAdministratorEmail(new SendCaseUpdateByAdministratorEmailCommand({id: this.props.caseId, caseId: this.props.id}))
                    .then(res =>{})
                    .catch(error =>{
                        console.log(error);
                    });
                }
            })
    }

    handleOnSuccess(){
        this.props.callback(this.state)
    }
    
    render() {
        const comment = this.state.comment;
        return (
            <form onSubmit={this.handleSubmit}>
                    <textarea value={comment} onChange={this.handleChange} rows={3} placeholder={this.props.language["write_a_comment"]} className="form-control"></textarea>
                    <button type="submit" className=" btn btn-success mt-1"><TranslatableText translationKey="send" /></button>
                    <br />
                    <br />
            </form>
            
        );

}
}

export default CommentForm;