import * as React from 'react';
import CaseEntryList from '../components/caseEntryList/CaseEntryList';
// import '../styles/custom.scss';
import { connect } from 'react-redux';
import Select, {OptionTypeBase, ValueType} from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Modal, ModalBody, ModalHeader, Popover, PopoverHeader, Row, Spinner, Table } from 'reactstrap';
import {
    AttachmentClient, CaseClient,
    CaseEntryClient,
    CaseEntryFileClient,
    CaseEntryFileResponse, CaseEntryResponse, CaseEntryStatusClient,
    CaseEntryTagClient, CaseResponse, ContactClient, CreateCaseEntryStatusRequest, CaseEntryFileInternalResponse, CreateCaseEntryTagRequest, CaseEntryFileInternalClient, CreateCaseEntryTagsRequest, CreateTagsRequest, DeleteCaseEntryTagRequest, DeleteCaseRequest, SendCaseUpdateByAdministratorEmailCommand, Status, TagClient, UpdateCaseRequest, FileParameter, UserClient, UserResponse, AssignCaseRequest, CaseResponseUser, OrganisationTinNumberResponse
} from '../ApiClient';
import { ApiConfig } from '../ApiConfig';
import CaseEntryLog from '../components/caseEntryList/CaseEntryLog';
import { Loading } from '../components/loading/loading';
import DownloadCasePdf from '../components/pdf/DownloadCasePdf';
import TranslatableText from "../Language/TranslateableText";
import { ApplicationState } from '../store';
import '../styles/loading.css';
import Swal from 'sweetalert2';
import FileUpload from '../components/fileUpload/FileUpload';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { AnyAaaaRecord } from 'dns';
import {toast} from "react-toastify";
import Tippy from "@tippyjs/react";

const apiConfig = new ApiConfig();

interface IState {
    caseId: string
    case: CaseResponse | undefined,
    selectedOption: statusAndValue | undefined,
    companyTags: ITag[],
    availableCompanyTags: ITag[],
    caseTags: ITag[],
    newTags: string[],
    files: CaseEntryFileResponse[],
    internalFiles: CaseEntryFileInternalResponse[],
    caseEntryLog: [],
    caseEntries: CaseEntryResponse[],
    statusUpdated: boolean,
    tagsUpdated: boolean,
    loadingTagsSelect: boolean,
    loadingStatusSelect: boolean,
    loadingCaseEntryList: boolean,
    loadingCaseEntryLog: boolean,
    amountOfComments: number,
    displayDeleteBox: boolean,
    displaySpinner: boolean,
    hasCaseWorkerAssigned: boolean,
    selectedFiles: File[],
    displayFilesList: boolean,
    users: UserResponse[],
    assignedUserEmails: string[],
    tempAssignedUserEmails: string[],
    assignUserDropdownOpen: boolean,
    newCaseWorkersAssigned: boolean,
    organisationTinNumbers: OrganisationTinNumberResponse[],
}

interface statusAndValue extends OptionTypeBase {
    // value: Status,
    // label: string
}

interface ITag {
    key: string,
    value: string,
    label: string
}

interface IProps {
    language: any | undefined,
    organisationId: string,
    organisationTinNumbers: OrganisationTinNumberResponse[],
    history: any
}
const userClient = new UserClient(new ApiConfig());
const caseClient = new CaseClient(apiConfig);
const tagClient = new TagClient(apiConfig);
const caseEntryFileClient = new CaseEntryFileClient(apiConfig);
const caseEntryClient = new CaseEntryClient(apiConfig);
const caseEntryFileInternalClient = new CaseEntryFileInternalClient(apiConfig);

class ViewCase extends React.Component<IProps, IState>{
    constructor(props: IProps) {
        super(props);
        this.state = {
            caseId: '',
            case: undefined,
            selectedOption: undefined,
            companyTags: [],
            availableCompanyTags: [],
            caseTags: [],
            newTags: [],
            files: [],
            internalFiles: [],
            caseEntryLog: [],
            caseEntries: [],
            statusUpdated: false,
            tagsUpdated: false,
            loadingTagsSelect: true,
            loadingStatusSelect: true,
            loadingCaseEntryList: true,
            loadingCaseEntryLog: true,
            amountOfComments: 0,
            displayDeleteBox: false,
            displaySpinner: false,
            hasCaseWorkerAssigned: false,
            selectedFiles: [],
            displayFilesList: false,
            users: [],
            assignedUserEmails: [],
            tempAssignedUserEmails: [],
            assignUserDropdownOpen: false,
            newCaseWorkersAssigned: false,
            organisationTinNumbers: props.organisationTinNumbers,
        }
        this.handleUploadFiles = this.handleUploadFiles.bind(this)
    }
    options: statusAndValue[] = [
        { value: 5, label: this.props.language["status.new"] },
        { value: 10, label: this.props.language["status.accepted"] },
        { value: 20, label: this.props.language["status.on_going"] },
        { value: 30, label: this.props.language["status.resolved"] },
        { value: 40, label: this.props.language["status.rejected"] }
    ]

    componentDidMount() {
        let caseId = this.getCaseIdRoutingProps();
        caseClient.byId(caseId)
            .then(response => {
                this.setState({ caseId: response.id!, case: response });
                this.handleSelect(response.status!)
                this.setState({ loadingStatusSelect: false })
                this.updateTags()
                this.handleGetCaseFiles(response.id!)
                this.handleHasCaseWorkerAssigned(response.id!);
                this.handleGetAssignedUsers(response.users!);
            }).catch(e => {
                console.log(e);
            })
    }
    handleGetAssignedUsers = async (users: CaseResponseUser[]) => {
        userClient.organsationWithRole().then(response => {
            this.setState({ users: response });
            const assignedUserEmails = response.filter(u => users.map(us => us.id).includes(u.id)).map(u => u.email!);
            this.setState({ assignedUserEmails: assignedUserEmails, tempAssignedUserEmails: assignedUserEmails });
        });
    }
    handleGetCaseFiles = async (id: string) => {
        await caseEntryFileClient.file(id!)
            .then(res => {
                this.setState({ files: res })
            }).catch(e => {
                console.log(e + 'failed retreiving files')
            });
        await caseEntryFileInternalClient.file(id!)
            .then(res => {
                this.setState({ internalFiles: res });
            }).catch(e => {
                console.log(e + 'failed retreiving internal files')
            });
    }
    handleHasCaseWorkerAssigned = async (id: string) => {
        await caseClient.hasCaseWorker(id)
            .then(res => {
                this.setState({ hasCaseWorkerAssigned: res.hasCaseWorker! })
            })
    }
    downloadFile = async (id: string) => {
        var attachmentClient = new AttachmentClient(apiConfig);
        attachmentClient
            .download(id)
            .then(response => {
                const url = window.URL.createObjectURL(
                    new Blob([response] as any, { type: response.headers!["content-type"] })
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", id); //or any other extension
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch(error => {
                console.log('error downloading..', error);
            });
    };
    updateTags() {
        Promise.all([tagClient.organisation(), tagClient.case(this.state.caseId), caseEntryClient.byId(this.state.caseId)])
            .then(responses => {
                this.setState({ loadingCaseEntryList: true })
                this.setState({
                    companyTags: responses[0].map(tag => ({ key: tag.id, value: tag.id, label: tag.tagTitle } as ITag)),
                    caseTags: responses[1].map(tag => ({ key: tag.tagId, value: tag.tagId, label: tag.tagTitle } as ITag)),
                    availableCompanyTags: responses[0]
                        .filter(companyTag =>
                            !responses[1].some(caseTag => caseTag.tagId == companyTag.id) && companyTag)
                        .map(tag => ({ key: tag.id, value: tag.id, label: tag.tagTitle } as ITag)),
                    caseEntries: responses[2],
                    tagsUpdated: false,
                    loadingTagsSelect: false,
                    loadingCaseEntryList: false,
                    loadingCaseEntryLog: false,
                });
                var amount = responses[2].filter(entry => entry.entryType == "Web.API.Entities.CaseEntry.CaseEntryComment")
                this.setState({ amountOfComments: amount.length })
            })

    }
    getCaseIdRoutingProps() { // find better fix with routing
        let param = this.props.match.params;
        let stringified = JSON.stringify(param);
        return stringified.slice(7, -2);
    }
    handleChange = (e: any) => { // status change
        this.setState({ selectedOption: e });
    }
    handleSelect(selectedOption: Status) {
        switch (selectedOption) {
            case 5:
                this.setState({ selectedOption: this.options[0] })
                break;
            case 10:
                this.setState({ selectedOption: this.options[1] })
                break;
            case 20:
                this.setState({ selectedOption: this.options[2] })
                break;
            case 30:
                this.setState({ selectedOption: this.options[3] })
                break;
            case 40:
                this.setState({ selectedOption: this.options[4] })
                break;
        }
    }
    handleStatusSubmit = () => {
        if (!this.state.hasCaseWorkerAssigned) {
            this.handlePromptUserToAssignCase()
        }
        let caseClient = new CaseClient(apiConfig);
        let caseEntryStatusClient = new CaseEntryStatusClient(apiConfig);
        let contactClient = new ContactClient(apiConfig);

        let request: UpdateCaseRequest =
            new UpdateCaseRequest(
                {
                    "id": this.state.caseId,
                    "status": this.state.selectedOption!.value
                });

        caseClient.casePut(request)
            .then(response => {

                caseEntryStatusClient.caseEntryStatus(
                    new CreateCaseEntryStatusRequest({ // creates a caseEntryStatus
                        "caseId": this.state.caseId,
                        "status": this.state.selectedOption!.value
                    })).then(response => {
                        this.setState({ statusUpdated: true })
                        setTimeout(() => this.setState({ statusUpdated: false }), 3000);
                        if (this.state.case != undefined) {
                            contactClient.caseUpdateByAdministratorEmail(new SendCaseUpdateByAdministratorEmailCommand({ id: this.state.case.id, caseId: this.state.case.caseId }))
                                .then(res => { })
                                .catch(error => {
                                    console.log(error);
                                });
                        }


                    }).catch(error => {
                        console.log(error);
                    });

            }).catch(error => {
                console.log(error);
            });

    }
    handleTagChange = (tag: any, actionMeta: any) => {
        // if (!this.state.hasCaseWorkerAssigned) {
        //     this.handlePromptUserToAssignCase()
        // }
        switch (actionMeta.action) {
            case 'create-option':
            case 'select-option':
                this.setState({ newTags: [...this.state.newTags, tag[tag.length - 1].label] })
                break;
            case 'remove-value':
                this.handleDeleteCaseEntryTag(actionMeta.removedValue.value)
                break;
        }
    }
    handleDeleteCaseEntryTag = (removedValue: string) => {
        var caseEntryTagClient = new CaseEntryTagClient(apiConfig);

        this.setState({ tagsUpdated: true })

        caseEntryTagClient.caseEntryTagDelete(
            new DeleteCaseEntryTagRequest({
                "tagId": removedValue,
                "caseId": this.state.caseId,
            })).then(() => {
                this.updateTags()
            });
    }
    handleTagsSubmit = (tag: any) => {
        var tagClient = new TagClient(apiConfig);
        var entryTagClient = new CaseEntryTagClient(apiConfig);

        this.setState({ tagsUpdated: true })

        tagClient
            .tag(new CreateTagsRequest({ "tagTitles": [...tag] }))
            .then(response => {

                const tags = response.map(e => new CreateCaseEntryTagRequest({
                    "tagId": e.id,
                    "caseId": this.state.caseId,
                }));

                entryTagClient
                    .caseEntryTagPost(new CreateCaseEntryTagsRequest({ tags: tags }))
                    .then(() => this.updateTags())
            });
    }
    isLoading = () => {
        return this.state.loadingCaseEntryList
            || this.state.loadingCaseEntryLog
            || this.state.loadingTagsSelect
            || this.state.loadingStatusSelect
    }
    handleDeleteCase = () => {
        this.setState({ displaySpinner: true })
        try {
            caseClient.caseDelete(new DeleteCaseRequest({ id: this.state.case!.id }))
                .then(response => {
                    this.setState({ displaySpinner: false });
                    this.props.history.push("/")
                });
        } catch (error) {
            console.log(error)
        }
    }
    handlePromptUserToAssignCase = () => {
        Swal.fire('Remember to assign this case to a caseworker!')
    }
    handleRemoveFile = (index: File) => {
        var array = [...this.state.selectedFiles]; // make a separate copy of the array
        var i = array.indexOf(index);
        if (i !== -1) {
            array.splice(i, 1);
            this.setState({ selectedFiles: array });
            // this.setState({ contentHeight: this.state.contentHeight - 45 })
        }
    };
    fileChangeHandler = (file: any) => {
        let joinedArray = this.state.selectedFiles.concat(
            file
        );
        this.setState({
            selectedFiles: joinedArray,
        });
        this.setState({ displayFilesList: true });
        // this.setState({ contentHeight: this.state.contentHeight + 45 })
    };
    handleUploadFiles() {
        if (this.state.selectedFiles.length > 0) {
            let files: FileParameter[] = [];
            for (let i = 0; i < this.state.selectedFiles.length; i++) {
                files.push({
                    data: this.state.selectedFiles[i],
                    fileName: this.state.selectedFiles[i].name
                });
            }
            for (var i = 0; i < files.length; i++) {
                caseEntryFileInternalClient
                    .uploadInternalFile(undefined, files[i], this.state.case!.id) // (undefined) --> weird hack, but has to be there..
                    .then((response) => {
                        return Swal.fire('Success!')
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        } else {
            console.log('no files in state')
        }
    }
    handleDownloadReport = async () => {
        const prepToast = toast.info(this.props.language["preparing_download"])
        await caseClient.downloadCaseReport(this.state.caseId)
            .then(res => {
                let dataBlob = new Blob([res.data] as any, { type: res.headers!["content-type"] });
                const url = window.URL.createObjectURL(dataBlob);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", this.state.caseId + "_Report");
                document.body.appendChild(link);
                link.click();
                link.remove();
                toast.dismiss(prepToast)
                toast.info("Downloaded PDF")
            }).catch(() => {
                toast.info('Oops! Download went wrong..')
            })
    }
    handleAddUserEmail = (email: string) => {
        let emails: string[] = this.state.assignedUserEmails;
        if (emails.includes(email)) return;
        emails.push(email);
        this.setState({ assignedUserEmails: emails, newCaseWorkersAssigned: true });
    }
    handleAssignUser = () => {
        if (this.state.tempAssignedUserEmails.length == 0 || this.state.caseId == '') {
            // just wont do anything if no user is selected
        } else {
            caseClient.assignCase(new AssignCaseRequest({
                caseId: this.state.caseId,
                caseWorkerEmails: this.state.tempAssignedUserEmails
            }))
            .then(() => {
                //reload case to get assigned users
                caseClient.byId(this.state.case!.caseId!)
                .then(response => {
                    this.setState({ 
                        caseId: response.id!, 
                        case: response,
                        assignedUserEmails: this.state.users.filter(u => response.users!.map(us => us.id).includes(u.id)).map(u => u.email),
                        newCaseWorkersAssigned: false,
                    });
                }).catch(e => {
                    console.log(e);
                })
            })
        }
    }
    toggleAssignUserDropdown = () => {
        this.setState(prevState => ({
            assignUserDropdownOpen: !prevState.assignUserDropdownOpen
        }));
    }

    arraysEqual(a, b) {
        if (a.length !== b.length) return false;
        a.sort();
        b.sort();
        for (let i = 0; i < a.length; i++) {
            if (a[i] !== b[i]) return false;
        }
        return true;
    }

    handleAssignedEmailsChange = (dropdownValues: ValueType<{ value: string, label: string }> | ValueType<{ value: string, label: string }>[] | null) => {
        const emails : string[] = dropdownValues && Array.isArray(dropdownValues) ? dropdownValues.map(x => x.value) : []
        this.setState({
            tempAssignedUserEmails: emails,
            newCaseWorkersAssigned: !this.arraysEqual(this.state.assignedUserEmails, emails)
        })
    }
    public render() {
        const selectedOption = this.state.selectedOption;
        if (!this.state.hasCaseWorkerAssigned) {
            this.handlePromptUserToAssignCase
        }
        if (this.isLoading()) {
            return (<Loading />)
        }
        if (this.state.case != undefined) {
            var refId = this.state.case.caseId;
        }
        return (
            <React.Fragment>
                <Row>
                    <div className="caseInformation col-8 mt-5">
                        <div className="caseDetails">
                            <div className="col-sm-12 col-md-12 col-lg-12" id="caseHeader" >
                                <h3 className="headingText" style={{ float: 'left' }}><TranslatableText translationKey="case_information" /></h3>
                                {this.state.case !== undefined &&
                                    <span style={{ height: '30px', float: 'right' }} >
                                        {/* <DownloadCasePdf caseId={this.state.case.caseId} title={this.state.case.title} description={this.state.case.description} caseEntries={this.state.caseEntries}
                                            status={this.state.selectedOption} tags={this.state.caseTags} createdOn={this.state.case.createdOn}
                                            attachments={this.state.files.length.toString()} comments={this.state.amountOfComments.toString()} lastModified={this.state.case.modifiedOn} /> */}
                                        {/*<Button className="ml-2" size="sm" color="info" onClick={() => this.handleDownloadReport()}><TranslatableText translationKey="PDF" /></Button>*/}
                                        <Button className="ml-2" size="sm" color="danger" onClick={() => this.setState({ displayDeleteBox: true })}><TranslatableText translationKey="archive" /></Button>
                                    </span>
                                }
                            </div>
                            <br /><br />
                            <section className="col-4">
                                <Modal isOpen={this.state.displayDeleteBox}>
                                    <ModalHeader>
                                        <b><TranslatableText translationKey="delete_case" /></b><br />
                                        <small><TranslatableText translationKey="delete_case_are_you_sure" /></small>
                                    </ModalHeader>
                                    <ModalBody>

                                        <br /> <br />
                                        <Button color="danger" onClick={() => this.handleDeleteCase()}>
                                            {this.state.displaySpinner &&
                                                <Spinner size="sm" color="light" className="ml-1" />
                                            }
                                            {!this.state.displaySpinner &&
                                                <TranslatableText translationKey="delete" />
                                            }
                                        </Button>
                                        <Button className="ml-2" color="info" onClick={() => this.setState({ displayDeleteBox: false })}>
                                            <TranslatableText translationKey="go_back" />
                                        </Button>

                                    </ModalBody>
                                </Modal>
                            </section>
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <label><b><TranslatableText translationKey="title" /></b></label>
                                <p>{this.state.case && this.state.case.title}</p>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <label><b><TranslatableText translationKey="description" /></b></label>
                                <p>{this.state.case && this.state.case.description}</p>
                            </div>
                            {this.state.case && this.state.case.organisationTin && this.state.case.organisationTinNumber && this.state.organisationTinNumbers.length > 1 && (
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <label><b><TranslatableText translationKey="organisation" /></b></label>
                                    <p>{this.state.case && this.state.case.organisationTin.displayName} ({this.state.case && this.state.case.organisationTinNumber})</p>
                                </div>
                            )}
                            <div className="col-sm-4 col-md-4 col-lg-4">
                                <label><b><TranslatableText translationKey="case_status" /></b></label>
                                {!this.state.loadingStatusSelect &&
                                    <Select options={this.options} placeholder={selectedOption && selectedOption.label} onChange={this.handleChange.bind(this)} />
                                }
                                <button id="updateCase" type="submit" value={'update'} onClick={this.handleStatusSubmit} className="btn btn-success mt-1"><TranslatableText translationKey="update_status" /></button>
                                <Popover placement="right" isOpen={this.state.statusUpdated} target="updateCase">
                                    <PopoverHeader><TranslatableText translationKey="status_updated" />!</PopoverHeader>
                                </Popover>
                            </div>
                            <div className="col-sm-4 col-md-4 col-lg-4 my-4"> {/* Select assigned user section */}
                                <p><b><TranslatableText translationKey="users" /></b></p>
                                <Tippy content={<TranslatableText translationKey={"tippy.caseworks_only_see_their_cases"}/>}>
                                    <div>
                                        <Select
                                          isMulti
                                          options={this.state.users.filter(u => !this.state.tempAssignedUserEmails.includes(u.email!)).map(u => ({value: u.email, label: u.email}))}
                                          placeholder={<TranslatableText translationKey={"caseworker"} />}
                                          onChange={this.handleAssignedEmailsChange}
                                          value={this.state.tempAssignedUserEmails.map(email => ({value: email, label: email}))}
                                        />
                                    </div>
                                </Tippy>
                                {this.state.newCaseWorkersAssigned && this.state.tempAssignedUserEmails.length > 0 && (
                                    <div className="mt-1">
                                        <Button color="info" onClick={() => this.handleAssignUser()}><TranslatableText translationKey="assign" /></Button>
                                    </div>
                                )}
                            </div>
                            <div className="col-sm-4 col-md-4 col-lg-4">
                                <label><b><TranslatableText translationKey="tags" /></b></label>
                                {!this.state.loadingTagsSelect &&
                                    <CreatableSelect key={(this.state.tagsUpdated) as unknown as string}
                                        isClearable={false}
                                        isMulti
                                        options={this.state.availableCompanyTags}
                                        closeMenuOnSelect={true}
                                        onChange={this.handleTagChange}
                                        defaultValue={this.state.caseTags}
                                    />
                                }
                                <button id="updateTags" type="submit" disabled={this.state.tagsUpdated} onClick={() => this.handleTagsSubmit(this.state.newTags)} className="btn btn-success mt-1"><TranslatableText translationKey="save_tags" /></button>
                                <Popover placement="right" isOpen={this.state.tagsUpdated} target="updateTags">
                                    <PopoverHeader><TranslatableText translationKey="tags_updated" />!</PopoverHeader>
                                </Popover>
                            </div>
                            <br />
                            <div className="col-sm-4 col-md-4 col-lg-4">
                                <label><b><TranslatableText translationKey="upload_internal_files" /></b></label>
                                <FileUpload file={this.fileChangeHandler} language={this.props.language} />
                                <br />
                                {this.state.displayFilesList && (
                                    <Table size="sm" striped>
                                        <tbody>
                                            {React.Children.toArray(this.state.selectedFiles.map((files) => {
                                                return (
                                                    <>
                                                        <tr key={this.state.selectedFiles.indexOf(files)}>

                                                            <td>{files.name}</td>
                                                            <td>
                                                                <span style={{ cursor: 'pointer' }}></span>
                                                                <FontAwesomeIcon
                                                                    icon={faTrashAlt}
                                                                    size="sm"
                                                                    style={{ color: "grey" }}
                                                                    onClick={() => this.handleRemoveFile(files)}
                                                                />
                                                            </td>
                                                        </tr>
                                                    </>
                                                );
                                            }))}
                                        </tbody>
                                    </Table>
                                )}
                                <button id="uploadFiles" type="submit" disabled={this.state.selectedFiles.length > 0 ? false : true} onClick={this.handleUploadFiles} className="btn btn-success mt-1">
                                    <TranslatableText translationKey="upload" />
                                </button>
                            </div>
                        </div>

                        <div className="caseEntryList">
                            {!this.state.loadingCaseEntryList &&
                                <CaseEntryList caseEntries={this.state.caseEntries} caseId={this.state.caseId} id={refId} />
                            }
                            <br />
                        </div>
                    </div>
                    <div className="col-2" id="logList">
                        {!this.state.loadingCaseEntryLog &&
                            <CaseEntryLog files={this.state.files} status={this.state.selectedOption} tags={this.state.caseTags} />
                        }
                    </div>
                </Row>
            </React.Fragment>
        );
    }
};
const mapState = (state: ApplicationState) => ({
    language: state && state.language.language_resources,
    organisationId: state && state.organisation && state.organisation.currentOrganisationId,
    organisationTinNumbers: state && state.organisation && state.organisation.currentOrganisationTinNumbers,
} as IProps)

export default connect(mapState)(ViewCase)


