import * as React from 'react'
import { Badge } from 'reactstrap';
import { CaseEntryTagResponse } from '../../ApiClient';
import Tippy from '@tippyjs/react';

export function TagsTippy(props: {tags: CaseEntryTagResponse[]}) {
    if (props.tags.length > 0) {
        return (
            <Tippy
                content={(
                    props.tags.map(t => t.title+', ')
                )}
                placement="bottom"
                trigger="mouseenter"
            >
                <span>
                    <Badge color="info" pill id="tagsBadge">{props.tags[0].title}</Badge>
                </span>
            </Tippy>
        );
    } else return null
}