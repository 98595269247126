import * as React from 'react';
import _ from 'lodash';
import { DeleteUserRequest, UpdateUserRequest, UserClient, UserResponse, UserRoleResponse, OrganisationClient, FileParameter, OrganisationTinNumberResponse } from '../ApiClient';
import { ApiConfig } from '../ApiConfig';
import { ApplicationState } from '../store';
import { connect } from 'react-redux';
import { Loading } from '../components/loading/loading'
import { FormGroup, Button, Form, Input, Label, Table, Modal, ModalHeader, ModalBody, Row, Col } from 'reactstrap';
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import TranslatableText from "../Language/TranslateableText";
import AssignCaseToUser from '../components/settings/AssignCaseToUser'
import { faUserCog, faUserShield } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FileUpload from '../components/fileUpload/FileUpload'
import '../styles/custom.scss'
import OrganisationTinNumbers from '../components/settings/OrganisationTinNumbers';
import Tippy from "@tippyjs/react";
export interface IState {
    organisationId: string,
    organisationTinNumbers: OrganisationTinNumberResponse[],
    users: UserRoleResponse[],
    loading: boolean,
    email: string,
    name: string,
    displayInviteBox: boolean,
    displayUserSettingsUpgradePriv: boolean,
    displayUserSettingsDegradePriv: boolean,
    privilegesEmail: string,
    privilegesName: string,
    isAdmin: boolean,
    currUserId: string,
    displayDeleteUser: boolean,
    userToDelete: string,
    displayUploadBox: boolean,
    selectedFile: File[]; // somehow this has to be stored in a list... fix at a later point
}

interface IProps {
    organisationId: string,
    organisationTinNumbers: OrganisationTinNumberResponse[],
    history: any,
    language: any
}

class CompanySettings extends React.Component<IProps, IState> {

    userClient = new UserClient(new ApiConfig());
    organisationClient = new OrganisationClient(new ApiConfig());

    constructor(props: IProps) {
        super(props);
        this.state = {
            organisationId: this.props.organisationId,
            organisationTinNumbers: this.props.organisationTinNumbers,
            users: [],
            loading: true,
            email: '',
            name: '',
            displayInviteBox: false,
            displayUserSettingsUpgradePriv: false,
            displayUserSettingsDegradePriv: false,
            privilegesEmail: '',
            privilegesName: '',
            isAdmin: false,
            currUserId: '',
            displayDeleteUser: false,
            userToDelete: '',
            displayUploadBox: false,
            selectedFile: []
        }
    }

    public componentDidMount(): void {
        toast.configure()
        this.userClient.organsationWithRole().then(response => {
            this.setState({ users: response });
        })
        this.userClient.currentUser().then(response => {
            if (response.isAdmin == true) { // wierd fix, but the way it works.. 
                this.setState({ isAdmin: true, currUserId: response.id!, loading: false })
            } else {
                this.setState({ isAdmin: false, currUserId: response.id!, loading: false })
            }
        })
    }
    notifyToast = (action: string) => {
        toast(action, {
            position: "bottom-left",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    handleNotify = (user: any) => {
        this.userClient.admin(new UpdateUserRequest({ email: user.email, name: user.name }))
            .then(res => {
                this.notifyToast(this.props.language["list_updated"])
            })

    }
    handlePrivilegesCheck = (user: UserRoleResponse) => {
        user.organisationAdministrator ?
            this.setState({ privilegesEmail: user.email!, privilegesName: user.name!, userToDelete: user.id! }, () => this.setState({ displayUserSettingsDegradePriv: true }))
            : this.setState({ privilegesEmail: user.email!, privilegesName: user.name!, userToDelete: user.id! }, () => this.setState({ displayUserSettingsUpgradePriv: true }))
    }
    renderAdminState = (user: UserRoleResponse) => {
        if (this.state.isAdmin) {
            if (user.organisationAdministrator) {
                return (
                    <td
                      onClick={() => this.handlePrivilegesCheck(user)}
                      role="button"
                    >
                        <Tippy content={<TranslatableText translationKey={"admin"}/>}>
                            <div style={{width: "fit-content"}}>
                                <FontAwesomeIcon
                                    style={{ color: 'var(--info)' }}
                                    icon={faUserShield}
                                />
                            </div>
                        </Tippy>
                    </td>
                );
            } else {
                return (
                    <td
                      onClick={() => this.handlePrivilegesCheck(user)}
                    >
                        <Tippy content={<TranslatableText translationKey={"caseworker"}/>}>
                            <div style={{width: "fit-content"}}>
                                <FontAwesomeIcon
                                  style={{ color: 'gray' }}
                                  icon={faUserCog}
                                />
                            </div>
                        </Tippy>
                    </td>
                );
            }
        } else {
            return (<td></td>)
        }
    }
    renderTableHeader = () => {
        return (
            <tr>
                <th></th>
                <th><TranslatableText translationKey="name" /></th>
                <th><TranslatableText translationKey="email" /></th>
            </tr>
        )
    }
    renderTableData = () => {
        return this.state.users.map(user => {
            return (
                <tr key={user.email}>
                    {this.renderAdminState(user)}
                    <td>{user.name}</td>
                    <td>{user.email}</td>
                </tr>
            )
        })
    }
    renderTable = () => {
        if (this.state.loading) {
            return <Loading />
        }
        return (
            <Table className="cases-table">
                <thead>
                    {this.renderTableHeader()}
                </thead>
                <tbody>
                    {this.renderTableData()}
                </tbody>
            </Table>
        )
    }
    handleInviteUser = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        this.setState({ loading: true });
        this.userClient
            .organsationInvite(new UserResponse({ email: this.state.email, name: this.state.name }))
            .then(() => {
                this.userClient.organsation().then(response => {
                    this.setState({
                        users: response,
                        loading: false,
                        email: '',
                        name: ''
                    });
                })
            })
        this.setState({ displayInviteBox: false })
        this.notifyToast(this.props.language["user_invited"])

    }
    handleChangePrivileges = () => {
        this.userClient.admin(
            new UpdateUserRequest(
                {
                    name: this.state.privilegesName, email: this.state.privilegesEmail
                }))
            .then(() => {
                this.notifyToast("Success");
                this.setState({ displayUserSettingsUpgradePriv: false, displayUserSettingsDegradePriv: false });
                window.location.reload();
            })
    }
    handleDeleteUser() {
        this.userClient.user(
            new DeleteUserRequest(
                {
                    id: this.state.userToDelete
                }))
            .then(() => {
                this.notifyToast("success");
                window.location.reload();
            })
    }
    handleUploadLogo(e: any){
        e.preventDefault();
        console.log(this.state.selectedFile.length)
        if(this.state.selectedFile.length == 0){
            this.notifyToast("No file selected.")
            this.setState({displayUploadBox: false})
        }else if(this.state.selectedFile.length > 1){
            this.notifyToast("Only 1 file can be selected.")
            this.setState({displayUploadBox: false})
        }else if(this.state.selectedFile.length == 1){
            console.log('selectedFiles', this.state.selectedFile);
            let logo: FileParameter[] = []; // again, this should not be a list
            logo.push({
                data: this.state.selectedFile[0],
                fileName: this.state.selectedFile[0].name
              }); 
            this.organisationClient.upload(undefined, logo[0])
            .then(response => {
                this.notifyToast("Uploaded");
                location.reload();
            })
        }
    }
    fileChangeHandler = (file: File) => {
        let joinedArray = this.state.selectedFile.concat(
            file
          );
          this.setState({
            selectedFile: joinedArray,
          });

    };
    public render() {
        if (this.state.loading) return (<Loading />);
        return (
            <React.Fragment>
                <Row className="chart-row users">
                    <Col lg={{ size: 10, offset: 1 }} sm={{ size: 10, offset: 0 }} className="mt-4" >
                        <h1 className="page-title"><TranslatableText translationKey="users" /></h1>
                        <Row>
                        <Button color="info" onClick={() => { this.setState({ displayInviteBox: true }) }}>
                            + <TranslatableText translationKey="invite_user" />
                        </Button><br /><br />
                        { this.state.isAdmin &&
                        <Button color="info" className="ml-2" onClick={() => { this.setState({ displayUploadBox: true }) }}>
                            + <TranslatableText translationKey="upload_company_logo" />
                        </Button>
                        }
                        </Row>
                    </Col>
                </Row>
                <Row className="cases-row">
                    <Col lg={{ size: 8, offset: 1 }} sm={{ size: 10, offset: 0 }} xs={{ size: 10, offset: 0}} >
                        {this.renderTable()}
                        <section className="col-4">
                            <Modal isOpen={this.state.displayInviteBox} toggle={(_) => this.setState({displayInviteBox: !this.state.displayInviteBox})}>
                                <ModalHeader>
                                    <b>Invite User</b>
                                </ModalHeader>
                                <ModalBody>
                                    <Form onSubmit={this.handleInviteUser} className="w-50">
                                        <FormGroup>
                                            <Label for="email"><TranslatableText translationKey="email" /></Label>
                                            <Input type="email" name="email" id="email" placeholder={this.props.language["email"]}
                                                value={this.state.email}
                                                onChange={(e: any) => this.setState({ email: e.target.value })} />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="name"><TranslatableText translationKey="name" /></Label>
                                            <Input type="text" name="name" id="name" placeholder={this.props.language["name"]}
                                                value={this.state.name}
                                                onChange={(e: any) => this.setState({ name: e.target.value })} />
                                        </FormGroup>
                                        <FormGroup>
                                            <Button color="info"><TranslatableText translationKey="invite" /></Button>
                                            <Button className="ml-2" color="danger" onClick={() => { this.setState({ displayInviteBox: false }) }}><TranslatableText translationKey="go_back" /></Button>
                                        </FormGroup>
                                    </Form>
                                </ModalBody>
                            </Modal>
                        </section>
                        <section className="col-4">
                            <Modal isOpen={this.state.displayUploadBox} toggle={(_) => this.setState({displayUploadBox: !this.state.displayUploadBox})}>
                                <ModalHeader>
                                    <b><TranslatableText translationKey="upload_company_logo" /></b>
                                </ModalHeader>
                                <ModalBody>
                                    <FileUpload file={this.fileChangeHandler} language={this.props.language}/>        
                                    <br /> <br />
                                    <Button className="btn-whistle mr-1" onClick={e => this.handleUploadLogo(e)}>
                                        <TranslatableText translationKey="upload" />
                                    </Button>
                                    <Button className="btn-danger ml-1" style={{marginTop: "15px"}} onClick={e => this.setState({displayUploadBox: false})}>
                                        <TranslatableText translationKey="cancel" />
                                    </Button>
                                </ModalBody>
                            </Modal>
                        </section>
                        {/* Upgradeprivileges below */}
                        <section className="col-4">
                            <Modal isOpen={this.state.displayUserSettingsUpgradePriv} toggle={(_) => this.setState({displayUserSettingsUpgradePriv: !this.state.displayUserSettingsUpgradePriv})}>
                                <ModalHeader>
                                    <b><TranslatableText translationKey="user_settings" /></b>
                                </ModalHeader>
                                <ModalBody>
                                    <b><TranslatableText translationKey="assign_to_admin_desc" />?</b>
                                    <br />
                                    <br />
                                    <Label for="email"><TranslatableText translationKey="email" /></Label>
                                    <Input type="email" placeholder={this.state.privilegesEmail}
                                        value={this.state.email} disabled
                                    />
                                    <Label for="name"><TranslatableText translationKey="name" /></Label>
                                    <Input type="text" placeholder={this.state.privilegesName}
                                        disabled
                                    />
                                    <br />
                                    <Button color="info" onClick={() => this.handleChangePrivileges()}><TranslatableText translationKey="assign" /></Button>
                                    <Button className="ml-2" color="danger" onClick={() => { this.setState({ displayUserSettingsUpgradePriv: false }) }}><TranslatableText translationKey="go_back" /></Button>
                                    <Button className="ml-2 float-right" color="warning" onClick={() => { this.setState({ displayDeleteUser: true, displayUserSettingsUpgradePriv: false }) }}><TranslatableText translationKey="delete_user" /></Button>
                                </ModalBody>
                            </Modal>
                        </section>
                        {/* DegradePrivileges below */}
                        <section className="col-4">
                            <Modal isOpen={this.state.displayUserSettingsDegradePriv} toggle={(_) => this.setState({displayUserSettingsDegradePriv: !this.state.displayUserSettingsDegradePriv})}>
                                <ModalHeader>
                                    <b><TranslatableText translationKey="user_settings" /></b>
                                </ModalHeader>
                                <ModalBody>
                                    <b><TranslatableText translationKey="degrade_from_admin_desc" />?</b>
                                    <br />
                                    <br />
                                    <Label for="email"><TranslatableText translationKey="email" /></Label>
                                    <Input type="email" placeholder={this.state.privilegesEmail}
                                        value={this.state.email} disabled
                                    />
                                    <Label for="name"><TranslatableText translationKey="name" /></Label>
                                    <Input type="text" placeholder={this.state.privilegesName}
                                        disabled
                                    />
                                    <br />
                                    <Button color="info" onClick={() => this.handleChangePrivileges()}><TranslatableText translationKey="ok" /></Button>
                                    <Button className="ml-2" color="danger" onClick={() => { this.setState({ displayUserSettingsDegradePriv: false }) }}><TranslatableText translationKey="go_back" /></Button>
                                    <Button className="ml-2 float-right" color="warning" onClick={() => { this.setState({ displayDeleteUser: true, displayUserSettingsDegradePriv: false }) }}><TranslatableText translationKey="delete_user" /></Button>

                                </ModalBody>
                            </Modal>
                        </section>
                        {/* Delete User below */}
                        <section className="col-4">
                            <Modal isOpen={this.state.displayDeleteUser} toggle={(_) => this.setState({displayDeleteUser: !this.state.displayDeleteUser})}>
                                <ModalHeader>
                                    <b><TranslatableText translationKey="delete_user_desc" /> {this.state.privilegesName}</b>
                                </ModalHeader>
                                <ModalBody>
                                    <b><TranslatableText translationKey="no_going_back" /></b>
                                    <br />
                                    <br />
                                    <Label for="email"><TranslatableText translationKey="email" /></Label>
                                    <Input type="email" placeholder={this.state.privilegesEmail}
                                        value={this.state.email} disabled
                                    />
                                    <Label for="name"><TranslatableText translationKey="name" /></Label>
                                    <Input type="text" placeholder={this.state.privilegesName}
                                        disabled
                                    />
                                    <br />
                                    <Button color="danger" onClick={() => this.handleDeleteUser()}><TranslatableText translationKey="delete" /></Button>
                                    <Button className="ml-2 float-right" color="success" onClick={() => { this.setState({ displayUserSettingsDegradePriv: false, displayDeleteUser: false }) }}><TranslatableText translationKey="go_back" /></Button>
                                </ModalBody>
                            </Modal>
                        </section>
                    </Col>
                </Row>
                <br /><br />
                {this.state.organisationTinNumbers && this.state.organisationTinNumbers.length > 1 && <OrganisationTinNumbers />}                
                <br /><br />
                {this.state.isAdmin &&
                    <AssignCaseToUser />
                }
            </React.Fragment>
        )
    }
}

const mapState = (state: ApplicationState) => ({
    organisationId: state && state.organisation && state.organisation.currentOrganisationId,
    organisationTinNumbers: state && state.organisation && state.organisation.currentOrganisationTinNumbers,
    language: state && state.language.language_resources
} as IProps)

export default connect(mapState)(CompanySettings)
