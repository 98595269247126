import * as React from 'react'
import { faCheckSquare, faBriefcase, faCheckDouble, faFolderOpen, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Tippy from '@tippyjs/react';
import {Badge} from "reactstrap";

interface Props {
    status: number | undefined,
    language: any
}

const handleIcon = (status: any) => {
    switch (status) {
        case 5:
            return <FontAwesomeIcon icon={faFolderOpen} size="lg" />
        case 10:
            return <FontAwesomeIcon icon={faCheckSquare} size="lg" />
        case 20:
            return <FontAwesomeIcon icon={faBriefcase} size="lg" />
        case 30:
            return <FontAwesomeIcon icon={faCheckDouble} size="lg" />
        case 40:
            return <FontAwesomeIcon icon={faTimesCircle} size="lg" />
        default:
            return ""
    }
}
const handleEmumToString = (status: any, language: any) => {
    switch (status) {
        case 5:
            return language["status.new"]
        case 10:
            return language["status.accepted"]
        case 20:
            return language["status.on_going"]
        case 30:
            return language["status.resolved"]
        case 40:
            return language["status.rejected"]
        default:
            return ""
    }
}
export const StatusTippy: React.FC<Props> = ({ status, language }) => {

    if (status) {
        return (
            <Tippy
                content={(handleEmumToString(status, language))}
                placement="bottom"
                trigger="mouseenter"
            >
                <span>
                    {handleIcon(status)} - {handleEmumToString(status, language)}
                </span>
            </Tippy>
        );
    } else return null
};