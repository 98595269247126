import * as React from 'react'
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import { AttachmentClient } from '../../ApiClient';
import { ApiConfig } from '../../ApiConfig';
import TranslatableText from "../../Language/TranslateableText";
import { ApplicationState } from '../../store';

interface Props {
    fileId: string;
    createdBy?: string;
    createdOn: string;
    language: any;
}
export const CaseEntryInternalFile: React.FC<Props> = ({ fileId, createdBy, createdOn }) => {

    async function downloadFile(e: HTMLElement, id: string) {

        let attachmentClient = new AttachmentClient(new ApiConfig());

        await attachmentClient.download(id)
            .then(response => {
                if (response.fileName!.includes("myImg_")) {
                    response.data.text().then(dataText => {
                        ReactDOM.render(<img src={dataText} style={{ position: "unset" }} />, e.parentElement);
                    })
                } else {
                    let dataBlob = new Blob([response.data] as any, { type: response.headers!["content-type"] });
                    const url = window.URL.createObjectURL(dataBlob);
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", id);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                }
            })
            .catch(error => {
                console.log('error downloading..', error);
            });
    };

    return (
        <div>
            <Alert color="secondary"  className="LegalTech-noteBorder ml-5">
                <small>{createdOn} &nbsp; {createdBy}</small>
                <p><TranslatableText translationKey="internal_file" />: &nbsp;
                    <a className="alert-link" style={{ cursor: 'pointer' }} onClick={(e) => downloadFile(e.target as HTMLElement, fileId)}>{fileId}</a>
                </p>
            </Alert>
        </div>
    );
};  

const mapState = (state: ApplicationState) => ({
    language: state && state.language.language_resources
} as Props)

export default connect(mapState)(CaseEntryInternalFile)