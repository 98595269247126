import * as React from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {Row, Col, Button, Input, Label,  Popover, PopoverHeader, } from 'reactstrap';
import TranslatableText from "../../Language/TranslateableText";
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { ApiConfig } from '../../ApiConfig';
import { ContactClient, SendInformationEmailCommand } from '../../ApiClient';


class MailTextEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            emailAddress: '',
            emailBody: '',
            orgName: '',
            loading: true,
            popoverOpen: false
        }
    }
    componentDidMount(){
        let standardText = this.props.language["infomail"]
        if(this.props.name.length>0 && this.props.tinNumber.length>0 && standardText){
            if(this.state.emailBody.length<=0){
                var finalText = standardText.replace(/{{companyName}}/g, this.props.name)
                finalText = finalText.replace(/{{tinNumber}}/g, this.props.tinNumber)
                this.setState({emailBody: finalText})
            }
        }
    }
    componentDidUpdate(){
        let standardText = this.props.language["infomail"]
        if(this.props.name.length>0 && this.props.tinNumber.length>0 && standardText){
            if(this.state.emailBody.length<=0){
                var finalText = standardText.replace(/{{companyName}}/g, this.props.name)
                finalText = finalText.replace(/{{tinNumber}}/g, this.props.tinNumber)
                this.setState({emailBody: finalText})
            }
        }
    }
    onSubmitHandler = () =>{
        if(this.state.emailAddress.length > 0 && this.state.emailAddress.includes("@") && this.state.emailAddress.includes(".")){
            let contactClient = new ContactClient(new ApiConfig);

            contactClient.sendInformationEmail(
                new SendInformationEmailCommand({
                    receivingEmail: this.state.emailAddress, 
                    emailBody: this.state.emailBody})
                    ).then(response =>{
                        this.notifyToast();

                    })


        }else{
            this.setState({popoverOpen : true})
            setTimeout(() => this.setState({popoverOpen:false}), 4000);
        }
    }
    notifyToast = () => {
        toast.configure();
        toast('Email sent..', {
            position: "bottom-left",
            autoClose: 3500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };
    render(){
        return (
            <>
            {/* {this.state.loading && 
                <Loading />
            } */}
                <Row className="chart-row partner">
                    <Col lg={{ size: 10, offset: 1 }} sm={{ size: 10, offset: 0 }} className="mt-4">
                        <h1 className="page-title"><TranslatableText translationKey="send_information_title" /></h1>

                    </Col>
                </Row>
                <Row>
                    <Col lg={{size:8, offset: 1}} className="mt-2">
                        <Col lg={{size: 4, offset:0 }}>
                            <Label for="emailInput"><TranslatableText translationKey="send_email_to" />: </Label>
                            <Input type="email" className="mb-4" id="emailInput" value={this.state.emailAddress} onChange={e => this.setState({emailAddress: e.target.value})} placeholder={this.props.language["email"]} />
                            <Popover placement="right" isOpen={this.state.popoverOpen} target="emailInput">
                                <PopoverHeader><TranslatableText translationKey="not_valid_email" /></PopoverHeader>
                            </Popover>
                        </Col>
                        <Col lg={{size: 12, offset: 0}}>
                        <CKEditor
                            editor={ClassicEditor}
                            // margin on text?
                            data={this.state.emailBody}
                            onReady={(editor) => {
                                // You can store the "editor" and use when it is needed.
                                // console.log('Editor is ready to use!', editor);
                            } }
                            onChange={(event, editor) => {
                                let data = editor.getData();
                                this.setState({emailBody:data})
                            } }
                            onBlur={(event, editor) => {
                                // console.log('Blur.', editor);
                            } }
                            onFocus={(event, editor) => {
                                // console.log('Focus.', editor);
                            } } />
                            <Button className="mt-2 mb-2"id="sendBtn" onClick={this.onSubmitHandler}><TranslatableText translationKey="send" /></Button>
                            <Popover placement="right" isOpen={this.state.popoverOpen} target="sendBtn">
                                <PopoverHeader><TranslatableText translationKey="not_valid_email" /></PopoverHeader>
                            </Popover>
                        </Col>
                    </Col>
                </Row>    
            </>
        );
    }
};   
const mapState = (state) => ({
    language: state && state.language.language_resources, 
    organisationId: state && state.organisation && state.organisation.currentOrganisationId,
    name: state && state.organisation && state.organisation.currentOrganisationName,
    tinNumber: state && state.organisation && state.organisation.currentOrganisationTinNumber,
})

export default connect(mapState)(MailTextEditor)