import {
    FETCH_LANGUAGE_RESOURCES_BEGIN,
    FETCH_LANGUAGE_RESOURCES_SUCCESS,
    FETCH_LANGUAGE_RESOURCES_FAILURE
  } from "./languageResourcesActions";
  
  export const initialState = {
    language_resources: []
  };
  
  export default function languageResourcesReducer(state = initialState, action) {
    switch (action.type) {
      case FETCH_LANGUAGE_RESOURCES_BEGIN:
        // Mark the state as "loading" so we can show a spinner or something
        // Also, reset any errors. We're starting fresh.
        return {
          ...state
        };
  
      case FETCH_LANGUAGE_RESOURCES_SUCCESS:
        // All done: set loading "false".
        // Also, replace the items with the ones from the server
        return {
          ...state,
          language_resources: action.payload.language_resources
        };
  
      case FETCH_LANGUAGE_RESOURCES_FAILURE:
        // The request failed. It's done. So set loading to "false".
        // Save the error, so we can display it somewhere.
        // Since it failed, we don't have items to display anymore, so set `items` empty.
        //
        // This is all up to you and your app though:
        // maybe you want to keep the items around!
        // Do whatever seems right for your use case.
        return {
          ...state,
          language_resources: []
        };
  
      default:
        // ALWAYS have a default case in a reducer
        return state;
    }
  }
  