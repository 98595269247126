import * as React from 'react'
import { Badge } from 'reactstrap';
import {  UserResponse } from '../../ApiClient';
import Tippy from '@tippyjs/react';

export function AssignedTippy(props: {users: UserResponse[]}) {
    if (props.users.length > 0) {
        return (
            <Tippy
                content={(
                    props.users.map(t => t.name).join(", ")
                )}
                placement="bottom"
                trigger="mouseenter"
            >
                <span>
                {React.Children.toArray(props.users.map(t =>
                  <Badge color="info" pill id="tagsBadge" className={"mr-1"}>{t.name}</Badge>
                ))}
                </span>
            </Tippy>
        );
    } else return null
}