import * as React from 'react';
import { Component } from 'react';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import FormValidator from '../../components/validator/FormValidator';
import isEmail from 'validator/es/lib/isEmail';
import "../../components/validator/FormValidator.scss";
import { InlineLoading, Loading } from '../../components/loading/loading';
import { unset } from 'lodash';
import { CreateOrganisationAppSourceRequest, SignUpClient } from './ApiClientSignUp';


interface IProps {
  location: any;
  match: any;
}

interface IState {
  companyAadId: string;
  companyName: string;
  companyTin: string;
  companyAddress: string;
  companyEmail: string;
  name: string;
  email: string;
  isLoading: boolean;
  completed: boolean;
  gettingData: boolean;
  validation: any;
}

export default class SignUp extends React.Component<IProps, IState> {
  validator = new FormValidator([
    {
      field: "name",
      method: "isEmpty",
      validWhen: false,
      message: "Felt påkrævet."
    },
    {
      field: "email",
      method: isEmail,
      validWhen: true,
      message: "Ikke en gyldig email."
    },
  ]);

  signUnClient = new SignUpClient();
  constructor(props: any) {
    super(props);
    this.state = {
      companyAadId: "",
      companyName: "",
      companyTin: "",
      companyAddress: "",
      companyEmail: "",
      name: "",
      email: "",
      isLoading: false,
      completed: false,
      gettingData: true,
      validation: this.validator.valid()
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  public componentDidMount(): void {
    console.log(this.props.location.search);
    let token = new URLSearchParams(this.props.location.search).get("token");
    if (!token) {
      window.location.href = "/";
    } else {
      this.signUnClient.aadGetData(token).then(response => {
        if (response.subscription && response.subscription.saasSubscriptionStatus === "Subscribed") {
          window.location.href = "/";
        }
        this.setState({
          companyAadId: response.subscription!.purchaser!.tenantId!,
          email: response.subscription!.purchaser!.emailId!,
        });
      }).catch(err => {
        console.log(err);
      }).finally(() => {
        this.setState({
          gettingData: false
        });
      });
    }
  }

  handleInputChange(event: any) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    // @ts-ignore
    this.setState({
      [name]: value
    });
  }

  signUp = () => {
    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    if (!validation.isValid) {
      return;
    }

    this.setState({
      isLoading: true
    });


    let signUp = new CreateOrganisationAppSourceRequest();
    signUp.companyName = this.state.companyName;
    signUp.companyTin = this.state.companyTin;
    signUp.companyAddress = this.state.companyAddress;
    signUp.companyEmail = this.state.companyEmail;
    signUp.name = this.state.name;
    signUp.email = this.state.email;
    console.log(signUp);

    let token = new URLSearchParams(this.props.location.search).get("token");
    this.signUnClient.signUp(token!, signUp).then(response => {
      console.log(response);
      window.location.href = "/";
    }).catch(err => {
      console.log(err);
    }).finally(() => {
      this.setState({
        isLoading: false,
      });
    });
  };
  render() {
    // @ts-ignore
    let validation = this.completed // if the form has been submitted at least once
      ? this.validator.validate(this.state) // then check validity every time we render
      // @ts-ignore
      : this.state.validation;
    // @ts-ignore
    let { isLoading, completed, gettingData } = this.state;
    return (
      <>
        {isLoading && (
          <Loading />
        )}
        {gettingData && (
          <section className='vh-100'>
            <div className='mask d-flex align-items-center h-100 gradient-custom-3'>
              <div className='container h-100'>
                <div className='row d-flex justify-content-center align-items-center h-100'>
                  <div className='col-12 col-md-9 col-lg-7 col-xl-6'>
                    <div className='card' style={{ 'borderRadius': '15px' }}>
                      <div className='card-body p-5'>
                        <img src='Whistleblower-grafik.svg' style={{ 'maxWidth': '100%', 'position': 'unset', 'width': '80%', 'height': 'unset', 'margin': '0 10%' }} />
                        {/* <h2 className='text-uppercase text-center'>Digital Jura</h2> */}
                        <h5 className='text-center'>Loading Company Data...</h5>
                        <InlineLoading />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
        {(!isLoading && !completed && !gettingData) && (
          <section className='vh-100'>
            <div className='mask d-flex align-items-center h-100 gradient-custom-3'>
              <div className='container h-100'>
                <div className='row d-flex justify-content-center align-items-center h-100'>
                  <div className='col-12 col-md-9 col-lg-7 col-xl-6'>
                    <div className='card' style={{ 'borderRadius': '15px' }}>
                      <div className='card-body p-5'>
                        <img src='Whistleblower-grafik.svg' style={{ 'maxWidth': '100%', 'position': 'unset', 'width': '80%', 'height': 'unset', 'margin': '0 10%' }} />
                        {/* <h2 className='text-uppercase text-center'>Digital Jura</h2> */}
                        <h5 className='text-center'>Create Account.</h5>

                        <Form>
                          <h5 className='text-center'>Company information.</h5>
                          <FormGroup
                            className={validation.name.isInvalid ? "has-error" : ""}
                          >
                            <Label for="companyAadId">Azure AD Tenant Id *</Label>
                            <Input bsSize="sm" type="text" name="companyAadId" id="companyAadId" placeholder="AAD Id"
                              disabled={true}
                              // @ts-ignore
                              value={this.state.companyAadId}
                              onChange={this.handleInputChange} />
                            <span className="help-block">{validation.name.message}</span>
                          </FormGroup>
                          <FormGroup
                            className={validation.name.isInvalid ? "has-error" : ""}
                          >
                            <Label for="companyName">Company navn *</Label>
                            <Input bsSize="sm" type="text" name="companyName" id="companyName" placeholder="Company Navn"
                              // @ts-ignore
                              value={this.state.companyName}
                              onChange={this.handleInputChange} />
                            <span className="help-block">{validation.name.message}</span>
                          </FormGroup>
                          <FormGroup
                            className={validation.name.isInvalid ? "has-error" : ""}
                          >
                            <Label for="companyTin">Company TIN *</Label>
                            <small className="form-text text-muted">This will be the number users will use to rgister cases.</small>
                            <Input bsSize="sm" type="text" name="companyTin" id="companyTin" placeholder="TIN"
                              // @ts-ignore
                              value={this.state.companyTin}
                              onChange={this.handleInputChange} />
                            <span className="help-block">{validation.name.message}</span>
                          </FormGroup>
                          <FormGroup
                            className={validation.name.isInvalid ? "has-error" : ""}
                          >
                            <Label for="companyAddress">Company Address *</Label>
                            <Input bsSize="sm" type="text" name="companyAddress" id="companyAddress" placeholder="Company Address"
                              // @ts-ignore
                              value={this.state.companyAddress}
                              onChange={this.handleInputChange} />
                            <span className="help-block">{validation.name.message}</span>
                          </FormGroup>
                          <FormGroup
                            className={validation.name.isInvalid ? "has-error" : ""}
                          >
                            <Label for="companyEmail">Company Email *</Label>
                            <Input bsSize="sm" type="text" name="companyEmail" id="companyEmail" placeholder="Company Email"
                              // @ts-ignore
                              value={this.state.companyEmail}
                              onChange={this.handleInputChange} />
                            <span className="help-block">{validation.name.message}</span>
                          </FormGroup>

                          <h5 className='text-center'>Login information.</h5>

                          <FormGroup
                            className={validation.name.isInvalid ? "has-error" : ""}
                          >
                            <Label for="name">Name *</Label>
                            <small className="form-text text-muted">Name for the initial user.</small>
                            <Input bsSize="sm" type="text" name="name" id="name" placeholder="User Name"
                              // @ts-ignore
                              value={this.state.name}
                              onChange={this.handleInputChange} />
                            <span className="help-block">{validation.name.message}</span>
                          </FormGroup>
                          <FormGroup
                            className={validation.email.isInvalid ? "has-error" : ""}
                          >
                            <Label for="signupEmail">Email *</Label>
                            <small className="form-text text-muted">Email for the initial user.</small>
                            <Input bsSize="sm" type="email" name="email" id="signupEmail" placeholder="Email"
                              disabled={true}
                              // @ts-ignore
                              value={this.state.email}
                              onChange={this.handleInputChange} />
                            <span className="help-block">{validation.email.message}</span>
                          </FormGroup>
                          <div className='d-flex justify-content-center'>
                            <Button color="primary" size="lg" block onClick={() => this.signUp()}>Create Account</Button>
                          </div>

                          {/* <p className='text-center text-muted mt-5 mb-0'>Har du allerede en konto? <a href='/'
                            className='fw-bold text-body'><u>Login her</u></a></p> */}
                        </Form>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
        {(!isLoading && completed) && (
          <section className='vh-100'>
            <div className='mask d-flex align-items-center h-100 gradient-custom-3'>
              <div className='container h-100'>
                <div className='row d-flex justify-content-center align-items-center h-100'>
                  <div className='col-12 col-md-9 col-lg-7 col-xl-6'>
                    <div className='card' style={{ 'borderRadius': '15px' }}>
                      <div className='card-body p-5'>
                        <img src='oens_kvadrat.png' style={{ 'maxWidth': '100%' }} />
                        <h2 className='text-uppercase text-center mb-5'>Whistle Advisor</h2>
                        <h3 className='text-center'>Account Created.</h3>
                        {/* <h5 className='text-center'>Check your email for login details.</h5> */}
                        <p className='text-center text-muted mt-5 mb-0'><a href='/'
                          className='fw-bold text-body'><u>Login here</u></a></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </>
    )
  }
}
