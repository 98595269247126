import React, { useState } from "react";
import TranslatableText from "../../Language/TranslateableText";
import "./toggle_styles.css";

type ToggleProps = {
  className?: string,
  onToggle?: (isEnabled: boolean) => void,
  defaultValue?: boolean,
  disabled?: boolean,
  label?: string,
}

const Toggle = ({
                  className,
                  defaultValue,
                  onToggle,
                  disabled,
                  label,
                }: ToggleProps) => {
  const [isEnabled, setIsEnabled] = useState(defaultValue || false);
  const _onToggle = () => {
    if (disabled) return;
    else {
      setIsEnabled(!isEnabled);
      if (onToggle)
        onToggle(!isEnabled);
    }
  }

  return (
    <div className={className}>
    {
        !!label && (
          <div>
            <TranslatableText translationKey={label} />
          </div>
        )
    }
    <label className="switch">
      <input type="checkbox" checked={isEnabled} onChange={_onToggle} />
      <span className="slider round"></span>
    </label>
    </div>
  );
};

export default Toggle;
