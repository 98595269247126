import 'bootstrap/dist/css/bootstrap.css';
import 'tippy.js/dist/tippy.css'; // optional

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import configureStore from './store/configureStore';
import App from './App';
import { Auth0Provider } from "@auth0/auth0-react";
import SignUp from './appSource/sign-up/sign-up';
import { Route } from 'react-router';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') as string;
const history = createBrowserHistory({ basename: baseUrl });

const store = configureStore(history);

export default store;


if (window.location.toString().indexOf('/signUp/') > 0) {
    sessionStorage.removeItem('ltdToken');
    ReactDOM.render(
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <Route exact path='/signUp' component={SignUp}/>
                {/* <SignUp /> */}
        </ConnectedRouter>
    </Provider>,
    document.getElementById('root'));
} else {

ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <Auth0Provider
                domain="legaltech.eu.auth0.com"
                clientId="6f6Vw1917d2QFqjiMYPeC3m5nP7OTJ1z"
                redirectUri={window.location.origin}
                audience="https://api.whistle.360-advisor.com"
                scope="read:current_user update:current_user_metadata"
                useRefreshTokens={true}
                cacheLocation="localstorage"
            >
              <App />
            </Auth0Provider>
        </ConnectedRouter>
    </Provider>
    ,
    document.getElementById('root'));
}
