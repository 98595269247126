import * as React from 'react';
import { PulseLoader } from 'react-spinners';

import "./loader.scss";
export const Loading: React.FC<{}> = ({}) => {
    return (
        <div className="loader">
            <img alt="" src="loading.svg" />
        </div>
    );
};

export function InlineLoading(props: { loading?: boolean } = { loading: true }) {
    return (
        <PulseLoader
            cssOverride={{display: "block", margin: "0", borderColor: "var(--primary)"}}
            size={15}
            color={"var(--primary)"}
            loading={props.loading}
        />
    );
}