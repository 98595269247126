import * as React from 'react'
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import TranslatableText from "../../Language/TranslateableText";
import { ApplicationState } from '../../store';

interface Props {
    note: string;
    createdBy?: string;
    createdOn: string; 
    language: any | undefined
}
export const CaseEntryNote: React.FC<Props> = ({ note, createdBy, createdOn }) => {
    return (
        <Alert color="secondary" className="LegalTech-noteBorder ml-5">
            <small className="font-weight-light ">{createdOn} &nbsp; {createdBy} <TranslatableText translationKey="noted" />: </small>           
            <p className="text-break ">{note}</p>
        </Alert>
    );
};  
const mapState = (state: ApplicationState) => ({
    language: state && state.language.language_resources
} as Props)

export default connect(mapState)(CaseEntryNote)
