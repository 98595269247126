import { AxiosRequestConfig } from "axios";
// import store from './'

export class AnonymousApiBase {

    protected constructor() {
    }

    protected transformOptions = async (options: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
        // const { organisation } = store.getState();

        // if (organisation.organisationId){
        //     options.headers = {
        //         ...options.headers,
        //         "X-Organisation-Id": organisation.organisationId,
        //     };
        // }

        return Promise.resolve(options);
    };

    protected getBaseUrl(baseUrl: string) : string {
        return process.env.REACT_APP_API_URL!;
    }
}