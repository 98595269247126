import * as React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import TranslatableText from "../Language/TranslateableText";
import { ApplicationState } from '../store';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import Tippy from "@tippyjs/react";
interface Props {
    language: any | undefined
}
export const LogoutButton: React.FC<{}> = ({}) => {
    const { logout } = useAuth0();
    return (
      <Tippy content={<TranslatableText translationKey={"logout"} />}>
        <a
            onClick={() =>
                logout({
                    returnTo: window.location.origin,
                })
            }
        >
            <FontAwesomeIcon icon={faSignOutAlt} size="lg" className="mt-3"/>
        </a>
      </Tippy>
    );
};  

const mapState = (state: ApplicationState) => ({
    language: state && state.language.language_resources
} as Props)

export default connect(mapState)(LogoutButton)
