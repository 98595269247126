import React, { useState } from 'react';
import '../styles/NavMenu.css';
import { DocumentClient, OrganisationClient, UserClient, FileResponse } from '../ApiClient';
import { ApiConfig } from '../ApiConfig';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { Col, Nav, NavItem, NavLink, Row } from 'reactstrap';
import TranslatableText from '../Language/TranslateableText'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faHome, faQuestionCircle, faInfoCircle, faCogs, faArchive } from '@fortawesome/free-solid-svg-icons'
import ChangeOrganisation from './organisation/ChangeOrganisation';
import ChangeLanguage from './language/ChangeLanguage';
import { LogoutButton } from './Logout';
import ReactDOM from 'react-dom';
import Tippy from "@tippyjs/react";

interface IProps {
  organisationId: string,
  name: string,
  language: any | undefined,
  tinNumber: string,
}

const NavMenuSide: React.FC<IProps> = ({ name, tinNumber, organisationId }) => {

  const notifyToast = () => {
    toast.configure();
    toast('Downloading..', {
      position: "bottom-left",
      autoClose: 3500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  let isAdmin = true;
  const client = new OrganisationClient(new ApiConfig());
  const userClient = new UserClient(new ApiConfig());
  let companyLogo;
  const [hasCompanyLogo, setHasCompanyLogo] = useState<Boolean>(false);
  const [imgUrl, setImgUrl] = useState<ArrayBuffer | string | null>("");


  client.hasCompanyLogo()
    .then(response => {
      if (response === false) {
        setHasCompanyLogo(false)
      } else {
        client.downloadCompanyLogo()
          .then(response => {
            const imageBlob = response.data;
            const reader = new FileReader();
            reader.readAsDataURL(imageBlob);
            reader.onloadend = () => {
              const base64data = reader.result;
              setImgUrl(base64data);

            };
            setHasCompanyLogo(true);
          })
      }
    })

  userClient.currentUser().then(response => {
    if (response.isAdmin != true) { // wierd fix, but the way it works.. 
      isAdmin = false;
    }
  })
  return (
    <div className="col-2" id="left-navigation">
      <Nav vertical expand="md">
        <div id="nav-top-segment">
              {hasCompanyLogo === true &&
                <>
                  <div className="col-12 mb-4 company-logo-container">
                  <img id="companyLogo" src={imgUrl!.toString()} alt="company logo" height={100} />
                  <div className="col-12 mb-4 mt-4 company-information">
                    <Link to="/" className="ml-3 ml-xs-0 mt-4 mb-4 company-name">{name}</Link>
                    <br />
                    <Link to="/" className="ml-3 mt-4 ml-xs-0 mb-4 company-name company-tin">{tinNumber}</Link>
                    <hr />
                  </div>
                </div>
                </>
              }
          {hasCompanyLogo === false &&
            <>
              <FontAwesomeIcon icon={faBuilding} size="lg" />
              <div className="col-12 mb-4 mt-4 company-information">
                <Link to="/" className="ml-3 ml-xs-0 mt-4 mb-4 company-name">{name}</Link>
                <br />
                <Link to="/" className="ml-3 mt-4 ml-xs-0 mb-4 company-name company-tin">{tinNumber}</Link>
                <hr />
              </div>
            </>
          }
        </div>
          <NavItem className="mt-2 side-nav-item">
            <NavLink tag={Link} to="/CasesTable" className="side-nav-link" >
              <FontAwesomeIcon icon={faHome} size="lg" className="mr-4" />
              <span className="side-nav-text">
                <TranslatableText translationKey="dashboard" />
              </span>
            </NavLink>
          </NavItem>

          <NavItem className="mt-2 side-nav-item">
            <NavLink tag={Link} to="/Guides" className="side-nav-link" >
              <FontAwesomeIcon icon={faInfoCircle} size="lg" className="mr-4" />
              <span className="side-nav-text">
                <TranslatableText translationKey="guides" />
              </span>
            </NavLink>
          </NavItem>
          {isAdmin === true &&
            <NavItem className="mt-2 side-nav-item">
              <NavLink className="side-nav-link" tag={Link} to="/ArchivedCases">
                <FontAwesomeIcon icon={faArchive} size="lg" className="mr-4" />
                <span className="side-nav-text">
                  <TranslatableText translationKey="archived_cases" />
                </span>
              </NavLink>
            </NavItem>
          }
          <NavItem className="mt-2 side-nav-item">
            <NavLink className="side-nav-link" tag={Link} to="/CompanySettings">
              <FontAwesomeIcon icon={faCogs} size="lg" className="mr-3" />
              <span className="side-nav-text">
                <TranslatableText translationKey="company_settings" />
              </span>
            </NavLink>
          </NavItem>
      </Nav>
   
      {/* Bottom nav below */ }
      <div className="col-2 bottom-nav" >
        <Row>
          <Col md={4} sm={2} xs={8} className="mt-4 ">
            <ChangeOrganisation />
          </Col>
          <Col md={4} sm={2} xs={8} className="mt-4">
            <ChangeLanguage />
          </Col>
          <Col md={4} sm={2} xs={8} className="mt-4 pl-4 bottom-nav-signout">
            <LogoutButton />
          </Col>
        </Row>
      </div>
    </div >
  );
}

const mapState = (state: ApplicationState) => ({
  organisationId: state && state.organisation && state.organisation.currentOrganisationId,
  name: state && state.organisation && state.organisation.currentOrganisationName,
  tinNumber: state && state.organisation && state.organisation.currentOrganisationTinNumber,
  language: state && state.language.language_resources
} as IProps)

export default connect(mapState)(NavMenuSide)
