import * as React from 'react';
import './styles/custom.scss'
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'react-redux'
import { ApplicationState } from './store';
import { Loading } from './components/loading/loading';
import Layout from './components/Layout';
import { Route } from 'react-router';
import CasesTable from './pages/CasesTable';
import ViewCase from './pages/ViewCase';
import CompanySettings from './pages/CompanySettings';
import Guides from './pages/Guides';
import MailTextEditor from './components/textEditor/TextEditor';
import ArchivedCases from './pages/ArchivedCases';
import {ToastContainer} from "react-toastify";

function App() {
  const {
    isLoading,
    isAuthenticated,
    error,
    user,
    loginWithRedirect,
    logout,
    getAccessTokenSilently
  } = useAuth0();

  const key = useSelector((state: ApplicationState) => state && state.organisation && state.organisation.currentOrganisationId)  
  if (error) {
    return <div>Oops... {error.message}</div>;
  }
  if (isLoading) {
    return <Loading />
  }
  
  if (isAuthenticated) {   
    return (
      <Layout>
        <Route exact path='/'                 component={CasesTable} />
        <Route exact path='/CasesTable'       component={CasesTable} key={key} />
        <Route exact path='/viewCase/:id'     component={ViewCase} />
        <Route exact path='/Guides'           component={Guides} />
        <Route exact path='/CompanySettings'  component={CompanySettings} />
        <Route exact path='/TextEditor'       component={MailTextEditor} />
        <Route exact path='/ArchivedCases'    component={ArchivedCases} />
        <ToastContainer position='bottom-right' hideProgressBar={true} autoClose={3000} />
      </Layout>
    );
  } else {
      loginWithRedirect();
      return <Loading />
  }
}

export default App;
