import axios from "axios";
import { Config } from "../config/Config";

export const FETCH_LANGUAGE_RESOURCES_BEGIN   = "FETCH_LANGUAGE_RESOURCES_BEGIN";
export const FETCH_LANGUAGE_RESOURCES_SUCCESS = "FETCH_LANGUAGE_RESOURCES_SUCCESS";
export const FETCH_LANGUAGE_RESOURCES_FAILURE = "FETCH_LANGUAGE_RESOURCES_FAILURE";

export const fetchLanguageResourcesBegin = () => ({
  type: FETCH_LANGUAGE_RESOURCES_BEGIN
});

export const fetchLanguageResourcesSuccess = language_resources => ({
  type: FETCH_LANGUAGE_RESOURCES_SUCCESS,
  payload: { language_resources }
});

export const fetchLanguageResourcesFailure = error => ({
  type: FETCH_LANGUAGE_RESOURCES_FAILURE,
  payload: { error }
});
// use staic endpoint
const GLOBALIZATION_API_URL = `${Config.server_url}:${Config.port}${Config.api_prefix}/Globalize/`;  


export function fetchLanguageResources(language) {
  return dispatch => {
    dispatch(fetchLanguageResourcesBegin());

    return axios
      .get(`${GLOBALIZATION_API_URL}?localeId=${language}&resourceSet=WhistleAdvisor`)
      .then(res => {
        dispatch(fetchLanguageResourcesSuccess(res.data)); 
        return res.data;
      })
      .catch(error => {
        console.log('error:', error);
        dispatch(fetchLanguageResourcesFailure(error)
      )}
      );
  };
}