import moment from 'moment';
import 'moment/locale/da';
import * as React from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, Col, Row, Table } from 'reactstrap';
import { CaseClient, CaseEntryTagClient, CaseEntryTagResponse, CaseResponse, DeleteCaseRequest, StatusClient, StatusResponse } from '../ApiClient';
import { ApiConfig } from '../ApiConfig';
import StatusChart from '../components/chart/StatusChart';
import TagsChart from '../components/chart/TagsChart';
import { StatusTippy } from '../components/dashboard/StatusTippy';
import { TagsTippy } from '../components/dashboard/TagsTippy';
import { Loading } from '../components/loading/loading';
import { AssignedTippy } from '../components/settings/AssignedTippy';
import TranslatableText from "../Language/TranslateableText";
import { ApplicationState } from '../store';
import '../styles/chart.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort } from '@fortawesome/free-solid-svg-icons';


export interface IState {
    organisationId: string,
    cases: CaseResponse[],
    headers: string[],
    status: string[],
    statusForChart: StatusResponse[],
    loading: boolean,
    caseEntryTags: CaseEntryTagResponse[]
    caseEntryTagsChart: any[],
    displayAllTags: boolean,
    displayTagsChart: boolean
}

interface IProps {
    organisationId: string,
    history: any,
    language: any
}

class CasesTable extends React.Component<IProps, IState> {

    caseClient = new CaseClient(new ApiConfig());
    caseEntryTagClient = new CaseEntryTagClient(new ApiConfig());
    statusClient = new StatusClient(new ApiConfig());

    constructor(props: IProps) {
        super(props);
        this.state = {
            organisationId: this.props.organisationId,
            cases: [],
            caseEntryTags: [],
            caseEntryTagsChart: [],
            statusForChart: [],
            headers: [this.props.language["status"], this.props.language["title"], this.props.language["report_date"], this.props.language["last_changed"], this.props.language["responsible"]],
            status: ['New', 'Accepted', 'On going', 'Resolved'],
            loading: true,
            displayAllTags: false,
            displayTagsChart: true
        }
    }

    componentDidUpdate(prevProps: IProps) {
        if (prevProps.organisationId !== this.props.organisationId) {
            this.setState({ loading: true, statusForChart: [], caseEntryTagsChart: [] })
            this.caseClient.byCaseWorkerId().then(response => {
                this.setState({ cases: response });
            })
            this.caseEntryTagClient.caseEntryTagGet().then(response => {
                this.setState({ caseEntryTags: response });
            })
            this.statusClient.new().then(response => {
                this.setState({ statusForChart: response });
            });
            this.caseEntryTagClient.new().then(response => {
                if (response.length == 0) {
                    this.setState({ caseEntryTagsChart: [], loading: false });
                } else {
                    this.setState({ caseEntryTagsChart: [] }, () => this.setState({ caseEntryTagsChart: response, loading: false }));
                }
            })
        }
    }
    public componentDidMount(): void {
        this.setState({ organisationId: this.props.organisationId, statusForChart: [], caseEntryTagsChart: [] })
        this.caseClient.byCaseWorkerId().then(response => {
            this.setState({ cases: response });
        })
        this.statusClient.new().then(response => {
            this.setState({ statusForChart: response })
        });
        this.caseEntryTagClient.caseEntryTagGet().then(response => {
            this.setState({ caseEntryTags: response })
        });
        this.caseEntryTagClient.new().then(response => {
            if (response.length == 0) {
                this.setState({ caseEntryTagsChart: [], loading: false });
            } else {
                this.setState({ caseEntryTagsChart: [] }, () => this.setState({ caseEntryTagsChart: response, loading: false }));
            }
        });
    }
    renderTableHeader() {
        let headers = this.state.headers;
        return (
            <tr>
                <th>{headers[0]}</th>
                <th>{headers[1]}</th>
                <th>
                    {headers[2]}
                    <span className="ml-2" style={{ cursor: 'pointer' }}
                        onClick={() => this.handleSortList('createdOn')}><FontAwesomeIcon icon={faSort} color="#000000" />
                    </span>
                </th>
                <th>
                    {headers[3]}
                    <span className="ml-2" style={{ cursor: 'pointer' }}
                          onClick={() => this.handleSortList('modifiedOn')}><FontAwesomeIcon icon={faSort} color="#000000" />
                    </span>
                </th>
                <th align="right">{headers[4]}</th>
                <th align="right"></th>
            </tr>
        )
    }
    renderTableData() {
        if (this.state.cases.length <= 0) {
            return (
                <tr>
                    <td>
                        <TranslatableText translationKey="no_available_cases" />
                    </td>
                </tr>
            );
        }
        return this.state.cases.map((cases) => {
            const { id, caseId, title, status, createdOn, modifiedOn, users } = cases
            return (
                <tr key={caseId} onClick={() => this.props.history.push("/viewCase/" + caseId)}>
                    <td>{<StatusTippy status={status} language={this.props.language} />}</td>
                    <td>{this.trimData(title, 50, true)}</td>
                    <td>{this.handleTimeStamp(createdOn, createdOn)}</td>
                    <td>{this.handleTimeStamp(createdOn, modifiedOn)}</td>
                    <td align="right">
                        {users != undefined && users.length > 0 &&
                            <AssignedTippy users={users} />
                        }
                    </td>
                    {/* <td align="right">{this.handleDelete(modifiedOn, id)}</td> */}
                </tr>
            )
        });
    }
    handleSortList(value: string) {
        let cases = this.state.cases;
        switch (value) {
            case 'createdOn':
                const sortedList_byCreateOn = cases.sort((a, b) => (a.modifiedOn! > b.modifiedOn! ? 1 : -1))
                this.setState({ cases: sortedList_byCreateOn });
                break;
            case 'modifiedOn':
                const sortedList_byModifiedOn = cases.sort((a, b) => (a.modifiedOn! > b.modifiedOn! ? 1 : -1))
                this.setState({ cases: sortedList_byModifiedOn });
                break;
            default:
                break;

        }
    }
    tagsToggle = () => {
        this.setState(prevState => ({
            displayAllTags: !prevState.displayAllTags
        }));
    };
    handleTagsForTable(caseId: string | undefined) {
        if (caseId !== undefined && caseId.length > 0) {
            return <TagsTippy tags={this.state.caseEntryTags.filter(tag => tag.caseId === caseId)} />
        }
    }
    handleTagsForChart() {
        if (this.state.caseEntryTagsChart.length <= 0) {
            return <TranslatableText translationKey="no_data_available" />
        } else {
            // Only display the top 5 most used tags in list. 
            let tagAmount;
            let tempTagList: (string | undefined)[] = [];

            return this.state.caseEntryTagsChart.map((tag) => {
                if (this.state.caseEntryTagsChart.indexOf(tag) > 4) {
                    return null;
                }
                tagAmount = this.handleTagsOccurence(tag.title)
                if (!tempTagList.includes(tag.title)) {
                    tempTagList.push(tag.title)
                    return (
                        <p className="ml-4" key={this.state.caseEntryTagsChart.indexOf(tag)}> {tag.title}: {tagAmount}</p>
                    )
                }
            });
        }
    }
    handleTagsOccurence(value: any) {
        return this.state.caseEntryTagsChart.filter((v) => (v.title === value)).length;
    }
    trimData(data: any, length: number, endWithDots: boolean) {
        let trimmedData = (data as string).substring(0, length)
        if (endWithDots && data.length > 49)
            return trimmedData + "...";
        else
            return trimmedData
    }
    handleDelete(modifiedOn: any, id: string | undefined) {
        let now = new Date()

        if (modifiedOn != null) { // check if it has been modified
            let comparisonDate = moment(now).subtract(5, 'years')
            let dateObject = new Date(modifiedOn)
            let formattedModifiedOn = moment(dateObject)
            if (formattedModifiedOn.isBefore(comparisonDate)) { // check if modifiedOn is more than 5 years ago
                return <button type="submit" className="btn btn-danger btn-sm" onClick={() => this.handleDeleteOnSubmit(id)}><TranslatableText translationKey="delete" /></button>
            }
        }
        else {
            return null
        }
    }
    async handleDeleteOnSubmit(id: any) {
        let config = new ApiConfig()
        const caseClient = new CaseClient(config);
        try {
            await caseClient.caseDelete(new DeleteCaseRequest({ id: id })).then(
                // remove element from list.
            );
        } catch (error) {
        }
    }
    handleTimeStamp(createdOn: any, modifiedOn: any) { // change 'any'
        if (modifiedOn !== undefined) {
            return moment(modifiedOn).format('llll');
        } else {
            return moment(createdOn).format('llll');
        }
    }

    public render() {
        if (this.state.loading) return (<Loading />);
        return (
            <>
                <Row className="chart-row dashboard">
                    <div className="chart-container d-flex flex-row">
                        <Col lg={{ size: 4, offset: 1 }} sm={{ size: 10, offset: 0 }}>
                            <Card className="chart-card">
                                <CardBody>
                                    <div className="status-chart">
                                        {/* <StatusChart statuses={this.state.statusForChart} language={this.props.language}/> */}
                                        <StatusChart language={this.props.language} />

                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={{ size: 4 }}>
                            <Card className="chart-card tags-chart-container">
                                <CardBody>
                                    <div className="tags-chart-values">
                                        <p><TranslatableText translationKey="your_tags" /></p>
                                        {this.handleTagsForChart()}
                                        {this.state.caseEntryTagsChart.length > 5 &&
                                            <p className="ml-4">...</p>
                                        }

                                    </div>
                                    <div className="tags-chart">
                                        {this.state.caseEntryTagsChart.length > 0 && this.state.displayTagsChart &&
                                            <TagsChart caseEntryTags={this.state.caseEntryTagsChart} />
                                        }
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </div>
                </Row>
                <Row className="cases-row">
                    <Col lg={{ size: 8, offset: 1 }} sm={{ size: 10, offset: 0 }} >

                        <Table className="cases-table table-hover">
                            <thead>
                                {this.renderTableHeader()}
                            </thead>
                            <tbody>
                                {this.renderTableData()}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </>
        )
    }
}

const mapState = (state: ApplicationState) => ({
    organisationId: state && state.organisation && state.organisation.currentOrganisationId,
    language: state && state.language.language_resources
} as IProps)

export default connect(mapState)(CasesTable)