import * as React from 'react'
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import { ApplicationState } from '../../store';
import TranslatableText from "../../Language/TranslateableText";

interface Props {
    status: string;
    createdBy?: string;
    createdOn: string; 
    language: any | undefined
}

export const CaseEntryStatus: React.FC<Props> = ({ status, createdBy, createdOn, language }) => {
    
    let handleStatusTranslation = (status: string) =>{
        if(status == "On Going")
            status = "on_going"
        
        var payload = ("status."+status).toLowerCase();
        return language[payload]
    }
   
    return (
        <Alert color="secondary" className="LegalTech-noteBorder">
            <small className="font-weight-light ">{createdOn} &nbsp; {createdBy} <TranslatableText translationKey="changed_status" />: </small>           
            <p className="text-break ">{handleStatusTranslation(status)}</p>
        </Alert>
    );
};  	

const mapState = (state: ApplicationState) => ({
    language: state && state.language.language_resources
} as Props)

export default connect(mapState)(CaseEntryStatus)