import * as React from 'react';
import { connect } from 'react-redux';
import { Container, Row } from 'reactstrap';
import NavMenuTop from './NavMenuTop';
import NavMenuSide from './NavMenuSide';
import { actionCreators } from '../store/OrganisationState'
import { ApiConfig } from '../ApiConfig';
import {OrganisationClient, UserClient} from '../ApiClient';
import { Loading } from './loading/loading'
import getUserLocale from 'get-user-locale';
import {fetchLanguageResources} from '../store/languageResourcesActions'
import store from '..';
import '../styles/custom.scss'
import {userActionCreators} from "../store/UserState";
import {toast} from "react-toastify";

interface IProps {
    fetchLanguageResources: any
}

interface IState {
    children?: React.ReactNode,
    loading: boolean
}

class Layout extends React.PureComponent<IProps, IState> {
    constructor(props: IProps) {
        super(props)
        this.state = {
            children: this.props.children,
            loading: true
        }
    }

    componentDidMount() {
        const client = new OrganisationClient(new ApiConfig());
        const userClient = new UserClient(new ApiConfig());
        Promise.all([
            client.organisationGet().then(response => {
                store.dispatch(actionCreators.setOrganisations(response))
            }),
            userClient.currentUser().then(user => {
                store.dispatch(userActionCreators.setCurrentUserInformation(user.id!, user.isAdmin!))
            })
        ])
          .catch(() => toast.error("An error occurred"))
          .finally(() => this.setState({loading: false}));
        this.populateState()
    }
    async populateState(){
        let lang = localStorage.getItem('language');
        if (lang === undefined || lang === null) {
            lang = getUserLocale().substring(0,2);
            localStorage.setItem('language', lang) 
        };
        await this.props.fetchLanguageResources(lang)
        .then(() =>{
          this.setState({loading: false});
        })
    }
    public render() {
        if (this.state.loading){
            return <Loading />
        }
        return (
            <React.Fragment>
                <Container fluid={true} id="mainContainer" className="h-100"> 
                <Row className="h-100">
                    <NavMenuSide /> 
                    <div className="col-12 offset-2 h-100" id="caseContainer">
                        {this.props.children}
                    </div>
                </Row>
                </Container>
            </React.Fragment>
        );
    }
}
const mapDispatchToProps = {
    fetchLanguageResources,
}

export default connect(null, mapDispatchToProps)(Layout);
