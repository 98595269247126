import * as React from 'react';
import _ from 'lodash';
import { UpdateUserRequest, UserClient, UserResponse, CaseClient, CaseResponse, AssignCaseRequest } from '../../ApiClient';
import { ApiConfig } from '../../ApiConfig';
import { ApplicationState } from '../../store';
import { connect } from 'react-redux';
import { Loading } from '../loading/loading'
import { FormGroup, Button, Form, Input, Label, Table, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Dropdown, DropdownToggle, DropdownItem, DropdownMenu } from 'reactstrap';
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import TranslatableText from "../../Language/TranslateableText";
import moment from 'moment';
import { AssignedTippy } from '../settings/AssignedTippy'

export interface IState {
    organisationId: string,
    users: UserResponse[],
    loading: boolean,
    email: string,
    name: string,
    displayAssignModal: boolean,
    cases: CaseResponse[],
    headers: string[],
    assignedCaseId: string,
    dropdownOpen: boolean,
    assignedUserEmails: string[]
}

interface IProps {
    organisationId: string,
    history: any,
    language: any
}

class AssignCaseToUser extends React.Component<IProps, IState> {

    userClient = new UserClient(new ApiConfig());
    caseClient = new CaseClient(new ApiConfig());
    constructor(props: IProps) {
        super(props);
        this.state = {
            organisationId: this.props.organisationId,
            users: [],
            loading: true,
            email: '',
            name: '',
            displayAssignModal: false,
            cases: [],
            headers: [this.props.language["title"], this.props.language["last_changed"], this.props.language["responsible"]],
            assignedCaseId: '',
            dropdownOpen: false,
            assignedUserEmails: [],
        }
    }

    public componentDidMount(): void {
        toast.configure()
        this.userClient.organsationWithRole().then(response => {
            this.setState({ users: response });
        });

        this.caseClient.byCaseWorkerId().then(response => {
            this.setState({ cases: response, loading: false });
        })
    }
    notifyToast = (action: string) => {
        toast(action, {
            position: "bottom-left",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    handleNotify = (user: any) => { // OBS email should be sent caseworker when assigned to new case 

        this.userClient.admin(new UpdateUserRequest({ email: user.email, name: user.name }))
            .then(res => {
                this.notifyToast(this.props.language["list_updated"])
            })

    }
    trimData(data: any, length: number, endWithDots: boolean) {
        let trimmedData = (data as string).substring(0, length)
        if (endWithDots && data.length > 49)
            return trimmedData + "...";
        else
            return trimmedData
    }
    toggle = () => {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }
    modaltoggle = () => {
        this.setState(prevState => ({
            displayAssignModal: !prevState.displayAssignModal
        }));
        this.setState({ assignedCaseId: '', assignedUserEmails: [] });
    }
    handleTimeStamp(createdOn: any, modifiedOn: any) { // change 'any'
        if (modifiedOn !== undefined) {
            return moment(modifiedOn).format('llll');
        } else {
            return moment(createdOn).format('llll');
        }
    }
    renderTableHeader() {
        let headers = this.state.headers;
        return (
            <tr>
                <th>{headers[0]}</th>
                <th>{headers[1]}</th>
                <th align="right">{headers[2]}</th>
            </tr>
        )
    }
    renderTableData() {
        if (this.state.cases.length <= 0) {
            return (
                <tr>
                    <td>
                        <TranslatableText translationKey="no_available_cases" />
                    </td>
                </tr>
            );
        }
        return this.state.cases.map((cases) => {
            const { id, caseId, title, createdOn, modifiedOn, users } = cases
            return (
                <tr key={caseId} onClick={() => { this.setState({ displayAssignModal: true, assignedCaseId: id! }) }}>
                    <td>{this.trimData(title, 50, true)}</td>
                    <td>{this.handleTimeStamp(createdOn, modifiedOn)}</td>
                    <td align="right">
                        {users != undefined && users.length > 0 &&
                            <AssignedTippy users={users} />
                        }
                    </td>
                </tr>
            )
        });
    }
    handleAssignUser = () => {
        if (this.state.assignedUserEmails.length == 0 || this.state.assignedCaseId == '') {
            this.notifyToast("No caseworker selected")
            this.modaltoggle();
        } else {

            this.caseClient.assignCase(new AssignCaseRequest({
                caseId: this.state.assignedCaseId,
                caseWorkerEmails: this.state.assignedUserEmails
            })).then(() => {
                window.location.reload();
            })
        }
    }
    renderTable = () => {
        if (this.state.loading) {
            return <Loading />
        }
        return (
            <Table className="cases-table">
                <thead>
                    {this.renderTableHeader()}
                </thead>
                <tbody>
                    {this.renderTableData()}
                </tbody>
            </Table>
        )
    }

    // handleInviteUser = (e: React.FormEvent<HTMLFormElement>) => {
    //     e.preventDefault()

    //     this.setState({ loading: true });
    //     this.userClient
    //         .organsationInvite(new UserResponse({ email: this.state.email, name: this.state.name }))
    //         .then(() => {
    //             this.userClient.organsation().then(response => {
    //                 this.setState({ 
    //                     users: response, 
    //                     loading: false,
    //                     email: '',
    //                     name: ''
    //                 });
    //             })
    //         })
    //         this.setState({displayAssignModal: false})
    //         this.notifyToast(this.props.language["user_invited"])

    // }
    handleAddUserEmail = (email: string) => {
        let emails: string[] = this.state.assignedUserEmails;
        emails.push(email);
        this.setState({ assignedUserEmails: emails });
    }
    public render() {
        if (this.state.loading) return (<Loading />);
        return (
            <React.Fragment>
                <Row>
                    <Col lg={{ size: 10, offset: 1 }} sm={{ size: 10, offset: 0 }} className="mt-4" >
                        <h1 className="page-title" style={{ color: 'black' }}><TranslatableText translationKey="assign_user" /></h1>

                    </Col>
                </Row><br />
                <Row>
                    <Col lg={{ size: 8, offset: 1 }} sm={{ size: 10, offset: 0 }} >
                        {this.renderTable()}
                        <section className="col-4">
                            <Modal isOpen={this.state.displayAssignModal} toggle={this.modaltoggle}>
                                <ModalHeader>
                                    <b><TranslatableText translationKey="choose_user" /></b>
                                </ModalHeader>
                                <ModalBody>
                                    <p><TranslatableText translationKey="case_number" /> <b>{this.state.assignedCaseId}</b></p>
                                    {this.state.assignedUserEmails.length != 0 &&
                                        <p><TranslatableText translationKey="users" />:
                                            <span>
                                                <b>
                                                    {this.state.assignedUserEmails.map(x => x + " ")}
                                                </b>
                                            </span>
                                        </p>
                                    }
                                    <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                                        <DropdownToggle caret>
                                            <TranslatableText translationKey="users" />
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            {this.state.users.map(user => {
                                                return (
                                                    <>
                                                        <DropdownItem key={user.email} onClick={() => this.handleAddUserEmail(user.email!)}>{user.email}</DropdownItem>
                                                        <DropdownItem divider />
                                                    </>
                                                )
                                            })}
                                        </DropdownMenu>
                                    </Dropdown>
                                    <FormGroup className="mt-2" >
                                        <Button color="info" onClick={() => this.handleAssignUser()}><TranslatableText translationKey="assign" /></Button>
                                        <Button className="ml-2" color="danger" onClick={() => { this.setState({ assignedUserEmails: [], assignedCaseId: '' }, this.modaltoggle) }}><TranslatableText translationKey="go_back" /></Button>
                                    </FormGroup>
                                </ModalBody>
                            </Modal>
                        </section>
                    </Col>
                </Row>
            </React.Fragment>
        )
    }
}

const mapState = (state: ApplicationState) => ({
    organisationId: state && state.organisation && state.organisation.currentOrganisationId,
    language: state && state.language.language_resources
} as IProps)

export default connect(mapState)(AssignCaseToUser)
