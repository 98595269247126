import * as React from 'react';
import { connect } from 'react-redux'
import '../../styles/NavMenu.css';
import {
    Collapse,
    Navbar,
    Nav,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Dropdown
} from 'reactstrap';
import { OrganisationClient, OrganisationResponse } from '../../ApiClient';
import { ApplicationState } from '../../store';
import { actionCreators } from '../../store/OrganisationState';
import { push } from 'connected-react-router'
import { Link } from 'react-router-dom'
import TranslatableText from "../../Language/TranslateableText";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding } from '@fortawesome/free-solid-svg-icons';
import { ApiConfig } from '../../ApiConfig';
import Tippy from "@tippyjs/react";

interface IProps {
    organisationId: string,
    name: string,
    tinNumber: string,
    organisations: OrganisationResponse[],
    dispatch: any,
    history: any,
}
interface IState{
    orgDropdownOpen: boolean;
}
class ChangeOrganisation extends React.PureComponent<IProps,IState> {
    constructor(props: any) {
        super(props);
        this.state = {
            orgDropdownOpen : false
        }
    }

    organisationClient = new OrganisationClient(new ApiConfig())

    orgToggle = () => {
        this.setState(prevState => ({
            orgDropdownOpen: !prevState.orgDropdownOpen
        }));
    };
    changeCurrentOrganisation = (organisation: OrganisationResponse) => {
        this.organisationClient
            .changeOrganisation(organisation.id!)
            .then(r => 
            {
                this.props.dispatch(actionCreators
                    .setCurrentOrganisationInformation(organisation.id!, organisation.name!, organisation.tinNumber!))
                this.props.dispatch(push('/'))
            });
    }

    renderOrganisations = () => {
        if (this.props.organisations.length < 2) {
            return [];
        }
        let items = this.props.organisations.sort().map(organisation => {
            return (
                <DropdownItem 
                key={organisation.id}
                active={(organisation.id === this.props.organisationId)}
                onClick={this.changeCurrentOrganisation.bind(this, organisation, organisation.name)}
                >
                    {organisation.name}
                </DropdownItem>
            )
        })

        items = [
            <DropdownItem key="organisationsHeader" header></DropdownItem>,
            ...items,
            <DropdownItem key="divider" divider></DropdownItem>]

        return items;
    }

    public render() {
        return (
            <Navbar expand="md">
                <Collapse navbar>
                    <Nav className="ml-auto" navbar>
                        <Dropdown
                            isOpen={this.state.orgDropdownOpen}
                            toggle={this.orgToggle}
                            style={{zIndex:999, float:"right"}}
                        >
                            <Tippy placement={"top"} content={<TranslatableText translationKey={"change_organization"}/>}>
                                <div>
                                     <DropdownToggle  outline color="secondary">
                                        <FontAwesomeIcon icon={faBuilding} />
                                    </DropdownToggle>
                                </div>
                            </Tippy>
                            <DropdownMenu className={"overflow-auto"} right>
                                {this.renderOrganisations()}
                            </DropdownMenu>
                        </Dropdown>
                    </Nav>
                </Collapse>
            </Navbar>
        );
    }
}

const mapState = (state: ApplicationState) => ({
    organisationId: state && state.organisation && state.organisation.currentOrganisationId,
    name: state && state.organisation && state.organisation.currentOrganisationName,
    organisations: state && state.organisation && state.organisation.organisations,
    tinNumber: state && state.organisation && state.organisation.currentOrganisationTinNumber
} as IProps)

export default connect(mapState)(ChangeOrganisation)
