import React, { PureComponent } from 'react';
import { PieChart, Pie,  Legend, Tooltip, ResponsiveContainer, Cell } from 'recharts';
import '../../styles/chart.css'
import { Loading } from '../loading/loading'

let data = [];
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#9c28b1', '#3f51b5',  '#1b99f3', '#039588', '#8cc34b', '#feec3a', '#fec208', '#2196f3', '#fd9800', '#fe5721', '#f54337','#cddb38', '#93d6c3', '#a0294c', '#a77658', '#82cc68' ];
const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};
let containerHeight = 300;
let cx = 60;
let cy = 42;

class TagsChart extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      windowHeight: window.innerHeight,
      tags: this.props.caseEntryTags,
      loading: true,
      renderData: false
    }
  }
  componentDidMount(){
    data= [];

    this.handleResize();
    window.addEventListener('resize', this.handleResize)
    this.setState({tags: []}, ()=> this.setState({tags: this.props.caseEntryTags}))
    this.handleTags();
  }
  componentDidUpdate(){
    data= [];

    this.handleResize();
    window.addEventListener('resize', this.handleResize)
    this.setState({tags: []}, ()=> this.setState({tags: this.props.caseEntryTags})) 
    this.handleTags();
  }
  // Manual resizing.. 
  handleResize = () => {
    this.setState({
      windowHeight: window.innerHeight 
    });
    if(this.state.windowHeight > 900){
      containerHeight = 300;
      cy = 42;
      this.setState({loading: false})
    } else if(this.state.windowHeight <= 900){
      containerHeight = 200;
      cy = 40;
    } 
  }
  componentWillUnmount(){
    window.removeEventListener('resize', this.handleResize)
    this.setState({tags:[]})
  }
  handleTagsOccurence = (value) => {
    return this.props.caseEntryTags.filter((v) => (v.title=== value)).length;
  }
  handleTags = () =>{
    
    this.props.caseEntryTags.map((tag) => {
      let amount = this.handleTagsOccurence(tag.title)
      tag.amount = amount;
    });
    this.props.caseEntryTags.map((tag) =>{
      if(!data.some(el => el.name === tag.title)){
        data.push({name : tag.title, value: tag.amount})
      }
    });
    this.setState({loading: false})
  }
  
  render() {
    return (<>
      {this.state.loading &&
        <Loading />
      }{!this.state.loading &&
        <ResponsiveContainer width="100%" height={containerHeight}>
          <PieChart width="60%" className="tags-chart">
            <Pie
              dataKey="value"
              isAnimationActive={true}
              data={data}
              cx= {cx+'%'}
              cy= {cy+'%'}
              outerRadius="75%"
              innerRadius="37%"
              paddingAngle={3}
              >
              {
                data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      }
      </>
    );
  }
}

export default TagsChart
