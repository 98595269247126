import * as React from 'react';
import _ from 'lodash';
import { OrganisationTinNumberResponse } from '../../ApiClient';
import { ApplicationState } from '../../store';
import { connect } from 'react-redux';
import { Row, Col, Table } from 'reactstrap';
import 'react-toastify/dist/ReactToastify.css'
import TranslatableText from "../../Language/TranslateableText";

export interface IState {
    organisationId: string,
    organisationTinNumbers: OrganisationTinNumberResponse[],
    headers: string[],
}

interface IProps {
    organisationId: string,
    organisationTinNumbers: OrganisationTinNumberResponse[],
    history: any,
    language: any
}

class OrganisationTinNumbers extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            organisationId: this.props.organisationId,
            organisationTinNumbers: this.props.organisationTinNumbers,
            headers: [this.props.language["name"], this.props.language["tin"]],
        }
    }
    renderTableHeader() {
        let headers = this.state.headers;
        return (
            <tr>
                <th>{headers[0]}</th>
                <th>{headers[1]}</th>
            </tr>
        )
    }
    renderTableData() {
        return this.state.organisationTinNumbers.map((organisationTinNumber) => (
            <tr key={organisationTinNumber.id}>
                <td>{organisationTinNumber.displayName}</td>
                <td>{organisationTinNumber.tinNumber}</td>
            </tr>
        ));
    }

    renderTable = () => {
        return (
            <Table className="cases-table">
                <thead>
                    {this.renderTableHeader()}
                </thead>
                <tbody>
                    {this.renderTableData()}
                </tbody>
            </Table>
        )
    }
    public render() {
        return (
            <React.Fragment>
                <Row>
                    <Col lg={{ size: 10, offset: 1 }} sm={{ size: 10, offset: 0 }} className="mt-4" >
                        <h1 className="page-title" style={{ color: 'black' }}><TranslatableText translationKey="associated_tin_numbers" /></h1>
                    </Col>
                </Row><br />
                <Row>
                    <Col lg={{ size: 8, offset: 1 }} sm={{ size: 10, offset: 0 }} >
                        {this.renderTable()}
                    </Col>
                </Row>
            </React.Fragment>
        )
    }
}

const mapState = (state: ApplicationState) => ({
    organisationId: state && state.organisation && state.organisation.currentOrganisationId,
    organisationTinNumbers: state && state.organisation && state.organisation.currentOrganisationTinNumbers,
    language: state && state.language.language_resources
} as IProps)

export default connect(mapState)(OrganisationTinNumbers)
