import { faSave, faTags } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react'
import { Badge } from 'reactstrap';
import { OptionTypeBase } from 'react-select';
import {CaseEntryFileResponse, AttachmentClient} from '../../ApiClient'
import { ApiConfig } from '../../ApiConfig';
import TranslatableText from "../../Language/TranslateableText";
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import Tippy, { tippy } from '@tippyjs/react';


interface IProps{
    files?: CaseEntryFileResponse[],
    status?: statusAndValue,
    tags?: ITag[],
    language: any | undefined
}

interface ITag {
    key: string,
    value: string,
    label: string
}

interface statusAndValue extends OptionTypeBase {
    // value: Status,
    // label: string
}

function TagsComp(props:any) {
    if(props.props.length <= 0){
        return <small> - <i><TranslatableText translationKey="no_available_tags" /></i></small>
    }
    const listTags = props.props.map((prop:any) =>
    {        
        return <Badge className="secondary ml-1" key={prop.value}>{prop.label}</Badge>          
    });
    return (
      <div>
        {listTags}
      </div>
    );
}
function StatusComp(props:any){      
    let colorPicker = (currentStatus : any) =>{
        switch(currentStatus){
            case 5:
                return 'info'
            case 10:
                return 'primary'
            case 20:
                return 'warning'
            case 30:
                return 'success'
            case 40:
                return 'danger'
            default:
                return 'secondary'
        }
    }  
    return <Badge color={colorPicker(props.props.value)}>{props.props.label}</Badge>
}
function FilesComp(props:any) {
    if(props.props.length <= 0){
        return <small> - <i><TranslatableText translationKey="no_available_files" /></i></small>
    }
    const listFiles = React.Children.toArray(props.props.map((prop:any) =>
    {
        return <>
        <a className="alert-link" style={{cursor: 'pointer', color: 'black'}} onClick={() => downloadFile(prop.fileId)}>
            <small key={prop.id}>
                <FontAwesomeIcon icon={faSave} size="sm" style={{color: 'grey'}} />
                <b>
                &nbsp;&nbsp;
                    {prop.fileId}
                </b>
                <br />
            </small>
        </a>
        </>
    }));
    return (
      <div>
        {listFiles}
      </div>
    );
}
async function downloadFile (id:any) {

    let attachmentClient = new AttachmentClient(new ApiConfig());

    attachmentClient.download(id)
        .then(response =>{
            console.log(response)
            // if(response.headers!["content-type"] === "application/octet-stream"){
            //     alert("File can only be shown in browser.")
            // }else{
                const url = window.URL.createObjectURL(
                    new Blob([response.data] as any, { type: response.headers!["content-type"] })
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", id); 
                document.body.appendChild(link);
                link.click();
                link.remove();
            // }
        })
        .catch(error => {
            console.log('error downloading..', error);
        });
};
class CaseEntryLog extends React.Component<IProps, any>{
    constructor(props: any) {
        super(props);
        this.state = {
            loadingTags: true,
            loadingFiles: true,
            loadingStatus: true
        }
    }
    componentDidMount(){
        this.setState({loadingTags : false})
        this.setState({loadingFiles : false})
        this.setState({loadingStatus: false})
        
    }
    public render(){
        return(
            <div className="Log" >
                <label></label>
                <br />
                <h4>
                    {!this.state.loadingStatus &&
                        <StatusComp props={this.props.status} />
                    }
                </h4>
                <br />
                {!this.state.loadingTags &&
                <>
                    <FontAwesomeIcon icon={faTags} size="sm" style={{color: 'grey'}} />
                    <TagsComp props={this.props.tags}/>
                </>
                }
                <br />

                {!this.state.loadingFiles &&
                <>
                    <hr />
                    <small><TranslatableText translationKey="uploaded_files" /></small>
                    <FilesComp props={this.props.files}/>
                </>
                }
            </div>
        )
    }
}
const mapState = (state: ApplicationState) => ({
    language: state && state.language.language_resources
} as IProps)

export default connect(mapState)(CaseEntryLog)