import * as React from 'react';
// import '../../styles/custom.css';
import TranslatableText from "../../Language/TranslateableText";
import { toast } from 'react-toastify';
import { connect } from 'react-redux'
import * as piexif from 'piexifjs'
  
class FileUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    handleAlert = () =>{
        toast.configure()
        toast(this.props.language["file_type_not_supported"], {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
    }
    fileChangeHandler = (event) => {
        if (
          event.currentTarget.files !== null &&
          event.currentTarget.files.length > 0
        ) {
          let acceptedFile = this.checkFile(event.currentTarget.files[0])
          if(acceptedFile !== null){
            this.props.file(acceptedFile)
          }else{
              this.handleAlert();
          }
        }
      };

      checkFile = (file) =>{
        if(file.type == "application/x-msdownload" || 
            file.type == "application/x-ms-installer" || 
            file.type == "application/x-elf" || 
            file.type == "application/x-sh" || 
            file.type == "text/x-perl" || 
            file.type == "text/x-python")
        {
          return null;
        }
        return file;
      }

    render(){
        return(
            <>
            <input
              type="file"
              className="form-control-file"
              name="selectedFiles"
              id="fileInput"
              accept=".jpg, .png, .jpeg, .svg"
              onChange={this.fileChangeHandler}
            />
            </>
        );
    }
}
const mapState = (state) => ({
    language: state && state.language.language_resources
})
export default connect()(FileUpload); 