import React, { ChangeEvent, FormEvent } from 'react';
import { connect } from 'react-redux';
import { CaseEntryNoteClient, CreateCaseEntryNoteRequest } from '../../ApiClient';
import { ApiConfig } from '../../ApiConfig';
import TranslatableText from "../../Language/TranslateableText";
import { ApplicationState } from '../../store';

interface IProps {
    callback: any,
    caseId : string,
    language: any | undefined
}
interface IState {
    caseId : string,
    note: string
}
class NoteForm extends React.Component<IProps, IState> {
    constructor(props: any) {
        super(props);
        this.state = {
            caseId : '',
            note: ''
        } 
    }
    handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({ 
            note: e.target.value,       
            caseId: this.props.caseId 
        
        });
    }

    handleSubmit = (e: any) =>{
        e.preventDefault();

        let noteClient = new CaseEntryNoteClient(new ApiConfig());

        noteClient.caseEntryNote(new CreateCaseEntryNoteRequest(this.state))
            .then(res => {
                this.handleOnSuccess();
                this.setState({note: ''})
            })
    }

    handleOnSuccess(){
        this.props.callback(this.state)
    }
    
    render() { 
        const note = this.state.note;
        return (
            <form onSubmit={this.handleSubmit}>
                    <textarea value={note} onChange={this.handleChange} rows={3} placeholder={this.props.language["write_a_note"]} className="form-control"></textarea>
                    <button type="submit" className="btn-success btn mt-1"><TranslatableText translationKey="send" /></button>
            </form>
        );

}
}


export default NoteForm;
