import React, { PureComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare, faBriefcase, faCheckDouble, faFolderOpen, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer
} from "recharts";
import { Loading } from '../loading/loading';
import { StatusClient, StatusResponse } from '../../ApiClient';
import { ApiConfig } from '../../ApiConfig';


let data = [{}]
const setData = (language) => {
    return data = [ // Icons not working in this context, fix or remove at later point
        {
            name: language['status.new'],
            trimmedName: "",
            icon: <FontAwesomeIcon icon={faFolderOpen} size="xs" />,
            value: 0,
        },
        {
            name: language['status.accepted'],
            trimmedName: "",
            icon: <FontAwesomeIcon icon={faCheckSquare} size="xs" />,
            value: 0,
        },
        {
            name: language['status.on_going'],
            trimmedName: "",
            icon: <FontAwesomeIcon icon={faBriefcase} size="xs" />,
            value: 0,
        },
        {
            name: language['status.resolved'],
            trimmedName: "",
            icon: <FontAwesomeIcon icon={faCheckDouble} size="xs" />,
            value: 0,
        },
        {
            name: language['status.rejected'],
            trimmedName: "",
            icon: <FontAwesomeIcon icon={faTimesCircle} size="xs" />,
            value: 0,
        }
    ]
};

const handleStatus = (statuses) => {
    for (let i = 0; i < data.length; i++) {
        data[i].trimmedName = trimData(data[i].name, 4, true)
    }
    statuses.map((s) => {
        let status = s.status
        switch (status) {
            case 5:
                data[0].value++
                break;
            case 10:
                data[1].value++
                break;
            case 20:
                data[2].value++
                break;
            case 30:
                data[3].value++
                break;
            case 40:
                data[4].value++
                break;
            default:
                break;
        }
    });
}
const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
        return (
            <div style={{ backgroundColor: "WhiteSmoke", height: '40px', width: '160px', textAlign: 'center', padding: '7px' }}>
                <p className="label">{`${payload[0].payload.name}: ${payload[0].value}`}</p>
            </div>
        );
    }
    return null;
};
const trimData = (data, length, endWithDots) => {
    let trimmedData = (data).substring(0, length)
    if (endWithDots && data.length > length)
        return trimmedData + "...";
    else
        return trimmedData
}
let statusClient = new StatusClient(new ApiConfig());
let containerHeight = 300;
class StatusChart extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            windowHeight: window.innerHeight,
            loading: true,
            statusForChart: []
        }
    }

    componentDidMount() {
        this.handleResize();
        data = setData(this.props.language);
        statusClient.new()
            .then(res => {
                if (res.length > 0) {
                    handleStatus(res, this.props.language);
                }
                this.setState({ loading: false })
            });
        window.addEventListener('resize', this.handleResize);
    }
    componentDidUpdate() {
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
        if (data[0].value == 0) {
            statusClient.new()
                .then(res => {
                    if (res.length > 0) {
                        handleStatus(res, this.props.language);
                    }
                    this.setState({ loading: false })
                });
        }
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize)
    }
    handleResize = () => {
        this.setState({
            windowHeight: window.innerHeight
        });
        if (this.state.windowHeight >= 900) {
            containerHeight = 300;
        }
        if (this.state.windowHeight < 900) {
            containerHeight = 200;
        }
    }

    render() {
        if (this.state.loading) return (<Loading />)
        else {
            return (
                <ResponsiveContainer width="90%" height={containerHeight}>
                    <BarChart
                        data={data}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 30
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="trimmedName" />

                        <YAxis allowDecimals={false} />
                        <Tooltip content={<CustomTooltip />} />
                        <Bar dataKey="value" fill="#f85d3d" />

                    </BarChart>
                </ResponsiveContainer>
            );
        }
    }
}

export default StatusChart