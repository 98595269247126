import { Action, Reducer } from 'redux'
import {IGetCurrentUserResponse} from '../ApiClient'

const SET_USER_INFORMATION = 'SET_USER_INFORMATION'
type SET_USER_INFORMATION_TYPE = 'SET_USER_INFORMATION'

export type UserState = {
  loading: boolean
} & IGetCurrentUserResponse;

export type SetUserInformationAction = {
  type: SET_USER_INFORMATION_TYPE
} & IGetCurrentUserResponse;

export type UserActions = SetUserInformationAction;

export const userActionCreators = {
  setCurrentUserInformation: (id: string, isAdmin: boolean) => (
    {
      type: SET_USER_INFORMATION,
      id: id,
      isAdmin: isAdmin
    } as SetUserInformationAction),
};

export const reducer: Reducer<UserState> = (state: UserState | undefined, incomingAction: Action): UserState => {
  if (state === undefined) {
    return { id: '', isAdmin: false, loading: true }
  }

  const action = incomingAction as UserActions
  switch (action.type) {
    case SET_USER_INFORMATION:
      return {
        id: (action as SetUserInformationAction).id,
        isAdmin: (action as SetUserInformationAction).isAdmin,
        loading: true
      }
    default:
      return state
  }
};