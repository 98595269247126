import React, { Component } from "react";
import { connect } from "react-redux";
import { LanguageConsumer } from "./LanguageContext";

class TranslatableText extends Component {
  render() {
    let { translationKey, languages } = this.props;
    return (
      <LanguageConsumer>
        {
          () =>
            languages[translationKey] !== undefined
              ? languages[translationKey]
              : translationKey
        
        }
      </LanguageConsumer>
    );
  }
}

const mapStateToProps = state => ({
  languages: state.language.language_resources,
});

export default connect(mapStateToProps)(TranslatableText);
