import * as React from 'react';
import { connect } from 'react-redux'
import '../../styles/NavMenu.css'
import {
    Collapse,
    Navbar,
    NavbarToggler,
    Nav,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Dropdown
} from 'reactstrap';
import { ApplicationState } from '../../store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlag, faFlagCheckered, faGlobe } from '@fortawesome/free-solid-svg-icons';
import { Config } from '../../config/Config';
import Tippy from "@tippyjs/react";
import TranslatableText from "../../Language/TranslateableText";


interface IProps {
    dispatch: any,
    history: any,
    language: any | undefined
}
interface IState {
    currLang: string | null;
    languageDropdownOpen: boolean;
}
class ChangeLanguage extends React.PureComponent<IProps, IState> {
    constructor(props: any) {
        super(props);
        let lang = localStorage.getItem('language');
        this.state = {
            currLang: lang,
            languageDropdownOpen: false
        }
    }

    languageToggle = () => {
        this.setState(prevState => ({
            languageDropdownOpen: !prevState.languageDropdownOpen
        }));
    };

    public render() {
        return (
            <div >
                <Navbar expand="md" >
                    <Collapse navbar>
                        <Nav className="ml-auto" navbar>
                            
                        <Dropdown
                            isOpen={this.state.languageDropdownOpen}
                            toggle={this.languageToggle}
                            style={{float:"right"}}
                        >
                            <Tippy content={<TranslatableText translationKey={"choose_language"} />}>
                                <div>
                                    <DropdownToggle  outline color="secondary">
                                        <FontAwesomeIcon icon={faGlobe} />
                                    </DropdownToggle>
                                </div>
                            </Tippy>
                            <DropdownMenu className="dropdown-wrapper" right>
                                {Config.languages.map((lang) => (
                                    <DropdownItem
                                        key={lang.value}
                                        className={this.state.currLang === lang.value ? "selected" : ""}
                                        onClick={e => updateLanguage(lang.value, e)}
                                    >
                                    <FontAwesomeIcon
                                        icon={
                                            this.state.currLang === lang.value
                                                ? faFlagCheckered
                                                : faFlag
                                        }
                                    />&nbsp;
                                    {lang.name}
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                        </Nav>
                    </Collapse>
                </Navbar>
            </div>
        );
    }
}

function updateLanguage(value: string, e: React.MouseEvent<any, MouseEvent>): void {
    localStorage.setItem('language', value);
    window.location.reload();
}

const mapState = (state: ApplicationState) => ({
     language: state && state.language.language_resources
} as IProps)

export default connect(mapState)(ChangeLanguage)
