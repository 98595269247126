import * as React from 'react';
import { Button,  Row, Card, CardBody, CardTitle, CardSubtitle, Col } from 'reactstrap';
import TranslatableText from "../Language/TranslateableText";
import { ApiConfig } from '../ApiConfig';
import { ApplicationState } from '../store';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { DocumentClient } from '../ApiClient';

const apiConfig = new ApiConfig();

interface IState {
}

interface IProps {
    language: any |undefined,
    organisationId: string,
    name: string,

}

class Guides extends React.Component<any, IState>{
    constructor(props: any) {
        super(props);
        this.state = {
        }
    }
    notifyToast = () => {
        toast.configure();
        toast('Downloading..', {
            position: "bottom-left",
            autoClose: 3500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };
    onGetOrganisationDocument = () => { 
    this.notifyToast();
    let documentClient = new DocumentClient(new ApiConfig);
    documentClient.document(this.props.organisationId)
    .then(async response => {       
            let dataBlob = new Blob([response.data] as any, { type: response.headers!["content-type"] });
                        
            const url = window.URL.createObjectURL(dataBlob);
            const link = document.createElement("a");
            link.href = url;
            let fName:string = this.props.name ;
            link.setAttribute("download", fName);
            document.body.appendChild(link);
            link.click();
            link.remove();
            
    })
    .catch(error => {
        console.log('error downloading..', error);
    });
    }
    public render() {
        return (
            <React.Fragment>
              <Row className="chart-row partner">
                <Col lg={{size: 10, offset:1}} sm={{size: 10, offset:1}} className="mt-4" >
                    <h1 className="page-title"><TranslatableText translationKey="guides" /></h1>
                </Col>
              </Row>
              <Row>
                <Col lg={{size: 8, offset: 1}} sm={{size: 4, offset: 1}} className="guide-section">
                  <Card className="guide-card">
                    <CardBody className={"d-flex"}>
                      <FontAwesomeIcon icon={faQuestionCircle} color="#495057" className="fa-4x m-4 mr-5"/>
                      <div>
                        <CardTitle tag="h5"><TranslatableText translationKey="guide_for_whistleadvisor" /></CardTitle>
                        <CardSubtitle tag="h6" className="mb-2 text-muted"><TranslatableText translationKey="guide_for_whistleadvisor_description"  /> </CardSubtitle>
                        <Button className="guide-card-btn mt-3" color="info" href="https://whistleblower.blob.core.windows.net/common/WhistleAdvisor.pdf?sv=2019-10-10&st=2020-12-21T15%3A40%3A40Z&se=2100-12-22T15%3A40%3A00Z&sr=b&sp=r&sig=nGhUishEUwlThx9Cii2ySC9tqyZlzosF3AcwjvnYJWk%3D">
                          <TranslatableText translationKey="download"  />
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              {/* Hidden until functionality works */}
              {/*<Row className="mt-4 ">*/}
              {/*  <Col lg={{size: 8, offset: 1}} sm={{size: 8, offset: 1}} className="guide-section mt-2 mb-4">*/}
              {/*    <Card className="guide-card">*/}
              {/*      <CardBody className={"d-flex"}>*/}
              {/*        <FontAwesomeIcon icon={faEnvelope} color="#495057" className="fa-4x m-4 mr-5"/>*/}
              {/*        <div>*/}
              {/*          <CardTitle tag="h5"><TranslatableText translationKey="send_information_title" /></CardTitle>*/}
              {/*          <CardSubtitle tag="h6" className="mb-2 text-muted"><TranslatableText translationKey="send_information_description" /> </CardSubtitle>*/}
              {/*          <Button className="guide-card-btn mt-3" color="info"  href="/textEditor">*/}
              {/*            <TranslatableText translationKey="send"  />*/}
              {/*          </Button>*/}
              {/*        </div>*/}
              {/*      </CardBody>*/}
              {/*    </Card>*/}
              {/*  </Col>*/}
              {/*</Row>*/}
            </React.Fragment>
      );
    }
};
const mapState = (state: ApplicationState) => ({
    language: state && state.language.language_resources,
    organisationId: state && state.organisation && state.organisation.currentOrganisationId,
    name: state && state.organisation && state.organisation.currentOrganisationName,
    tinNumber: state && state.organisation && state.organisation.currentOrganisationTinNumber,
} as IProps)

export default connect(mapState)(Guides)


