const prod = {
  port: 443,
  server_url: "https://globalize.360-advisor.com",
  api_prefix: "/api",
  languages: [
    { value: "da", name: "Dansk" },
    { value: "en", name: "Engelsk" },
    { value: "de", name: "Tysk" },
    { value: "sv", name: "Svensk" },
    { value: "no", name: "Norsk" },
    { value: "fi", name: "Finsk" },
    { value: "pl", name: "Polsk" },
    { value: "nl", name: "Hollandsk" },
    { value: "cs", name: "Tjekkisk" },
    { value: "fr", name: "Fransk" }
  ]
};

const dev = {
  port: 443,
  server_url: "https://globalize.360-advisor.com",
  api_prefix: "/api",
  languages: [
    { value: "da", name: "Dansk" },
    { value: "en", name: "Engelsk" },
    { value: "de", name: "Tysk" },
    { value: "sv", name: "Svensk" },
    { value: "no", name: "Norsk" },
    { value: "fi", name: "Finsk" },
    { value: "pl", name: "Polsk" },
    { value: "nl", name: "Hollandsk" },
    { value: "cs", name: "Tjekkisk" },
    { value: "fr", name: "Fransk" }
  ]
};

export let Config = process.env.NODE_ENV === "development" ? dev : prod;
