import React, { useState } from 'react'
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import { AttachmentClient, CaseEntryFileClient, UpdateCaseEntryFileRequest } from '../../ApiClient';
import { ApiConfig } from '../../ApiConfig';
import TranslatableText from "../../Language/TranslateableText";
import { ApplicationState } from '../../store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2'

interface Props {
    fileId: string;
    createdBy?: string;
    createdOn: string;
    language: any;
}
export const CaseEntryFile: React.FC<Props> = ({ fileId, createdBy, createdOn }) => {
    const [publicDownload, setPublicDownload] = useState<boolean>(false);
    let caseEntryFileClient = new CaseEntryFileClient(new ApiConfig());
    let attachmentClient = new AttachmentClient(new ApiConfig());

    async function downloadFile(e: HTMLElement, id: string) {
        await attachmentClient.download(id)
            .then(response => {
                if (response.fileName!.includes("myImg_")) {
                    response.data.text().then(dataText => {
                        ReactDOM.render(<img src={dataText} style={{ position: "unset" }} />, e.parentElement);
                    })
                } else {
                    let dataBlob = new Blob([response.data] as any, { type: response.headers!["content-type"] });
                    const url = window.URL.createObjectURL(dataBlob);
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", id);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                }
            })
            .catch(error => {
                console.log('error downloading..', error);
            });
    };

    const handleSetPublicDownload = (value: boolean) => {
        let isVisible = value ? "visible" : "invisible"
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to make this attachment " + isVisible + " for the whistleblower?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes!'
        }).then((result) => {
            if (result.isConfirmed) {
                // do api call
                caseEntryFileClient.caseEntryFile(new UpdateCaseEntryFileRequest({ fileId: fileId, publicDownload: value }))
                    .then(res => {
                        setPublicDownload(value)
                        Swal.fire(
                            'Success!',
                            '',
                            'success'
                        )
                    }).catch(e => {
                        Swal.fire(
                            'Oops!',
                            'Something went wrong'
                        )
                        console.log('failed in updateCaseEntryFile: ' + e)
                    })

            }
        })
    }
    return (
        <div>
            <Alert color="secondary">
                <span>
                    <small>{createdOn} &nbsp; {createdBy}</small>
                    <p><TranslatableText translationKey="file_uploaded" />: &nbsp;
                        <a className="alert-link" style={{ cursor: 'pointer' }} onClick={(e) => downloadFile(e.target as HTMLElement, fileId)}>{fileId}</a>

                        <FontAwesomeIcon
                            icon={publicDownload ? faEye : faEyeSlash}
                            size="sm"
                            style={{ color: "grey", float: 'right', cursor: 'pointer' }}
                            onClick={() => handleSetPublicDownload(!publicDownload)} />
                    </p>

                </span>
            </Alert>
        </div>
    );
};

const mapState = (state: ApplicationState) => ({
    language: state && state.language.language_resources
} as Props)

export default connect(mapState)(CaseEntryFile)