import * as React from 'react'
import { CaseEntryNote } from './CaseEntryNote'
import { CaseEntryComment } from './CaseEntryComment'
import { CaseEntryFile } from './CaseEntryFile'
import { CaseEntryInternalFile } from './CaseEntryInternalFile'
import { CaseEntryTag } from './CaseEntryTag'
import moment from 'moment';
import CommentForm from '../comments/CommentForm';
import NoteForm from '../notes/NoteForm';
import { CaseEntryClient, CaseEntryResponse, Status } from '../../ApiClient'
import { ApiConfig } from '../../ApiConfig'
import { CaseEntryStatus } from './CaseEntryStatus'
import { ApplicationState } from '../../store'
import { connect } from 'react-redux'
import { Row } from 'reactstrap'

interface IProps {
    caseEntries: CaseEntryResponse[],
    caseId: string
    id: string | undefined,
    language: any | undefined
}

interface IState {
    caseEntries: CaseEntryResponse[],
}

const caseEntryClient = new CaseEntryClient(new ApiConfig());

const statuses: string[] = [
    'New',
    'Accepted',
    'On Going',
    'Resolved',
    'Rejected'
]

class CaseEntryList extends React.Component<IProps, IState>{
    constructor(props: any) {
        super(props);
        this.state = {
            caseEntries: this.props.caseEntries,
        }
    }

    statusAsString = (status: Status | undefined): string => {
        switch (status) { // might be an easier/more dynamic way?
            case 5:
                return statuses[0]
            case 10:
                return statuses[1]
            case 20:
                return statuses[2]
            case 30:
                return statuses[3]
            case 40:
                return statuses[4]
            default:
                return statuses[0]
        }
    }

    renderEntries() {
        const listItems = this.state.caseEntries.map((props: CaseEntryResponse) => {
            var entryType = props.entryType;           
            var entryTypeArray = entryType!.split(/[\s.]+/);
            var type = entryTypeArray[entryTypeArray.length - 1]
            switch (type) {
                case 'CaseEntryNote':
                    return <CaseEntryNote key={props.id} note={props.note!} createdBy={props.createdBy} createdOn={moment(props.createdOn).format('llll')} language={this.props.language} />
                case 'CaseEntryTag':
                    var isRemoved = true;
                    if (moment(props.modifiedOn).format('MM/DD-YY') === '01/01-01') {
                        isRemoved = false;
                    }
                    return <CaseEntryTag key={props.id} tag={props.tagTitle!} createdBy={props.createdBy} createdOn={moment(props.createdOn).format('llll')} deleted={isRemoved} language={this.props.language} />
                case 'CaseEntryFile':
                    return <CaseEntryFile key={props.id} fileId={props.fileId!} createdBy={props.createdBy} createdOn={moment(props.createdOn).format('llll')} language={this.props.language} />
                case 'CaseEntryFileInternal':
                    return <CaseEntryInternalFile key={props.id} fileId={props.fileId!} createdBy={props.createdBy} createdOn={moment(props.createdOn).format('llll')} language={this.props.language} />
                case 'CaseEntryComment':
                    return <CaseEntryComment key={props.id} comment={props.comment!} createdBy={props.createdBy} createdOn={moment(props.createdOn).format('llll')} language={this.props.language} />
                case 'CaseEntryStatus':
                    return <CaseEntryStatus key={props.id} status={this.statusAsString(props.status)} createdBy={props.createdBy} createdOn={moment(props.createdOn).format('llll')} language={this.props.language} />
            }
        }
        );
        return (
            <div>
                {listItems}
            </div>
        );
    }

    handleCommentCallback = (newComment: any) => {
        caseEntryClient.byId(this.props.caseId).then(response => this.setState({ caseEntries: response }))
    }
    handleNoteCallback = (newNote: string) => {
        caseEntryClient.byId(this.props.caseId).then(response => this.setState({ caseEntries: response }))
    }
    public render() {
        return (
            <>
                <div id="p-5">
                    <hr />
                    {this.renderEntries()}
                    <Row>
                        <div className="col-sm-5 col-md-5 col-lg-5">
                            <CommentForm callback={this.handleCommentCallback} caseId={this.props.caseId} id={this.props.id} language={this.props.language} />
                        </div>
                        <div className="col-sm-2 col-md-2 col-lg-2"></div>
                        <div className="col-sm-5 col-md-5 col-lg-5">
                            <NoteForm callback={this.handleNoteCallback} caseId={this.props.caseId} language={this.props.language} />
                        </div>
                    </Row>
                </div>
            </>
        )
    }
}
const mapState = (state: ApplicationState) => ({
    language: state && state.language.language_resources
} as IProps)

export default connect(mapState)(CaseEntryList)
