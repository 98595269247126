import * as React from 'react'
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import { ApplicationState } from '../../store';
import TranslatableText from "../../Language/TranslateableText";
import moment from 'moment';

interface Props {
    comment: string;
    createdBy?: string;
    createdOn: string;
    language: any | undefined;
}


export const CaseEntryComment: React.FC<Props> = ({ comment, createdBy, createdOn }) => {
    return (
        <div>
        <Alert color="secondary">
         
          <small className="font-weight-light">{createdOn} &nbsp;{createdBy == '' ? 'Whistleblower' : createdBy} <TranslatableText translationKey="commented" /></small>
           
            <p className="text-break ">{comment}</p>
        </Alert>
      </div> 
    );
};  
const mapState = (state: ApplicationState) => ({
  language: state && state.language.language_resources
} as Props)

export default connect(mapState)(CaseEntryComment)