import { AxiosRequestConfig } from "axios";
import { } from '@auth0/auth0-spa-js'
import { ApiConfig } from './ApiConfig';
import store from './'


export class AuthorizedApiBase {
    private apiConfig: ApiConfig;

    protected constructor(apiConfig: ApiConfig) {
        this.apiConfig = apiConfig;
    }

    protected transformOptions = async (options: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
        const { organisation } = store.getState();

        options.headers = {
             ...options.headers,
             Authorization: 'Bearer ' + await this.apiConfig.getAuthToken(),
        };
        return Promise.resolve(options);
    };

    protected getBaseUrl(baseUrl: string) : string {
        return process.env.REACT_APP_API_URL!;
    }
}