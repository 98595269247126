import * as React from 'react';
import moment from 'moment';
import 'moment/locale/da';
import {
    CaseClient,
    CaseEntryTagClient,
    CaseEntryTagResponse,
    StatusClient,
    GetAllDeletedCasesResponse,
} from '../ApiClient';
import { ApiConfig } from '../ApiConfig';
import { ApplicationState } from '../store';
import { connect } from 'react-redux';
import { Loading } from '../components/loading/loading'
import TranslatableText from "../Language/TranslateableText";
import '../styles/chart.css'
import { Col, Row, Table } from 'reactstrap';
import { AssignedTippy } from '../components/settings/AssignedTippy';
import Toggle from "../components/toggle/Toggle";
import {toast} from "react-toastify";


export interface IState {
    organisationId: string,
    userId: string,
    userIsAdmin: boolean,
    cases: GetAllDeletedCasesResponse[],
    filteredCases: GetAllDeletedCasesResponse[],
    casesFilteredByCurrentUser: boolean,
    headers: string[],
    loading: boolean,
    caseEntryTags: CaseEntryTagResponse[],
}

interface IProps {
    organisationId: string,
    userId: string,
    userIsAdmin: boolean,
    history: any,
    language: any
}

class ArchivedCases extends React.Component<IProps, IState> {
    caseClient = new CaseClient(new ApiConfig());
    caseEntryTagClient = new CaseEntryTagClient(new ApiConfig());
    statusClient = new StatusClient(new ApiConfig());

    constructor(props: IProps) {
        super(props);
        this.state = {
            organisationId: this.props.organisationId,
            userId: this.props.userId,
            userIsAdmin: this.props.userIsAdmin,
            cases: [],
            filteredCases: [],
            casesFilteredByCurrentUser: false,
            caseEntryTags: [],
            headers: [this.props.language["title"], this.props.language["archived"], this.props.language["deleted_by"], this.props.language["responsible"]],
            loading: true,
        }
    }
    public componentDidMount(): void {
        this.setState({
            organisationId: this.props.organisationId,
            userId: this.props.userId
        })
        this.caseClient.getDeletedCases().then(response => {
            this.setState({cases: response, filteredCases: response, loading:false})
        })
          .catch(error => {toast.info("An error occurred")})
          .finally(() => this.setState({loading: false}))
    }
    public componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (prevProps.userId !== this.props.userId || prevProps.userIsAdmin !== this.props.userIsAdmin) {
            this.setState({userId: this.props.userId, userIsAdmin: this.props.userIsAdmin})
        }
    }
    renderTableHeader() {
        let headers = this.state.headers;
        return (
            <tr>
                <th>{headers[0]}</th>
                <th>{headers[1]}</th>
                {/* <th>{headers[2]}</th> */}
                <th>
                    <div className={"d-flex justify-content-between align-items-end"}><span>{headers[3]}</span>
                    {this.state.userIsAdmin && (
                        <div className={"d-flex align-items-center"}>
                            <span className={"mr-2 font-weight-normal"}><TranslatableText translationKey="show_only_cases_assigned_to_me" /></span>
                            <Toggle onToggle={(val) => this.filterCasesByCurrentUser(val)} defaultValue={false}/>
                        </div>
                    )}
                    </div>
                </th>
            </tr>
        )
    }
    renderTableData() {
        if (this.state.filteredCases.length <= 0) {
            return (
                <tr>
                    <td>
                        <TranslatableText translationKey="no_available_cases" />
                    </td>
                </tr>
            );
        }
        return this.state.filteredCases.map((cases) => {
            const { caseId, title, deletedOn, deletedBy, users } = cases;

            return (
                <tr key={caseId}>
                    <td>{this.trimData(title, 50, true)}</td>
                    <td>{moment(deletedOn).format('llll')}</td>
                    {/* <td>{deletedBy}</td> */}
                    <td>
                        {users != undefined && users.length > 0 &&
                            <AssignedTippy users={users} />
                        }
                    </td>
                </tr>
            )
        });
    }
    filterCasesByCurrentUser(enableFilter: boolean) {
        if (enableFilter) {
            this.setState({
                filteredCases: this.state.cases.filter(x => x.users
                  && x.users.map(u => u.id).includes(this.state.userId)
                ),
                casesFilteredByCurrentUser: true
            })
        } else {
            this.setState({filteredCases: this.state.cases, casesFilteredByCurrentUser: false})
        }
    }
    trimData(data: any, length: number, endWithDots: boolean) {
        let trimmedData = (data as string).substring(0, length)
        if (endWithDots && data.length > 49)
            return trimmedData + "...";
        else
            return trimmedData
    }

    public render() {
        if (this.state.loading) return (<Loading />);
        return (
            <>
                <Row className="chart-row archived">
                    <Col lg={{ size: 10, offset: 1 }} sm={{ size: 10, offset: 0 }} className="mt-4" >
                        <h1 className="page-title"><TranslatableText translationKey="archived_cases" /></h1>
                        <TranslatableText translationKey="archived_cases_are_deleted_after_5_years" />
                    </Col>
                </Row>
                <Row className="cases-row">
                    <Col lg={{ size: 8, offset: 1 }} sm={{ size: 10, offset: 0 }} >
                        <Table className="cases-table table-hover">
                            <thead>
                                {this.renderTableHeader()}
                            </thead>
                            <tbody>
                                {this.renderTableData()}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </>
        )
    }
}

const mapState = (state: ApplicationState) => ({
    organisationId: state && state.organisation && state.organisation.currentOrganisationId,
    userId: state && state.user && state.user.id,
    userIsAdmin: state && state.user && state.user.isAdmin,
    language: state && state.language.language_resources
} as IProps)

export default connect(mapState)(ArchivedCases)